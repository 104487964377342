import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import * as z from "zod";
import * as AuthService from "@services/auth.service";
import {Button} from "@ui/button";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@ui/form";
import {Input} from "@ui/input";
import {toast} from "@ui/use-toast";
import useUser from "@hooks/useUser";
import {useEffect} from "react";
import {Label} from "@ui/label";

const accountFormSchema = z.object({
  fullName: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
});

type AccountFormValues = z.infer<typeof accountFormSchema>;

export function ProfileForm() {
  const {user} = useUser();
  const defaultValues: Partial<AccountFormValues> = {
    fullName: user?.fullName,
  };
  const form = useForm<AccountFormValues>({
    resolver: zodResolver(accountFormSchema),
    defaultValues,
  });
  useEffect(() => {
    form.reset(defaultValues);
  }, [user]);
  async function onSubmit(data: AccountFormValues) {
    if (!user) {
      return;
    }
    const {error} = await AuthService.updateUserInfo(user.id, data);
    if (error) {
      toast({
        variant: "destructive",
        title: error,
      });
      return;
    }
    toast({
      title: "Your profile has been updated",
    });
  }

  return (
    <div className="container mt-4 min-h-[80vh]">
      <Label>Email</Label>
      <Input className="mt-2" value={user?.email} disabled />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="fullName"
            render={({field}) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Your name" {...field} />
                </FormControl>
                <FormDescription>
                  This is the name that will be displayed on your profile and in
                  emails.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" variant={"outline"}>
            Update account
          </Button>

          {/* TODO delete the account: discuss with Hieu if would want to delete permenantly or just deactivate the account. */}
          <Button variant={"destructive"} type="button" className="ml-2">
            Delete account
          </Button>
        </form>
      </Form>
    </div>
  );
}
