import * as AuthAccess from "@access/auth.access";
import * as UserAccess from "@access/user.access";
import { User } from "@models/user";
import { EmailOtpType, Provider, User as UserSupabase } from "@supabase/supabase-js";

export async function signUp(option: {
	email: string;
	password: string;
}): Promise<{ error?: string, data: UserSupabase | null }> {
	return AuthAccess.signUp(option);
}

export async function signWithSocialLogin({
	provider,
	redirectTo,
}: {
	provider: Provider;
	redirectTo?: string;
}): Promise<{ error?: string }> {
	return AuthAccess.signInWithSocialLogin({ provider, redirectTo });
}

export async function updateUserInfo(userId: string, data: { fullName: string}): Promise<{ error?: string }> {
	return AuthAccess.updateUserInfo(userId, data);
}
export async function login(option: {
	email: string;
	password: string;
}): Promise<{ error?: string, data: UserSupabase | null }> {
	return AuthAccess.login(option);
}
export async function signInWithOtp(option: {
	email: string;
	redirectTo?: string;
}): Promise<{ error?: string, data: UserSupabase | null }> {
	return AuthAccess.signInWithOtp(option);
}
export async function verifyOtp(option: {
	email: string;
	token: string;
	type: EmailOtpType,
	redirectTo?: string
}): Promise<{ error?: string, data: UserSupabase | null }> {
	return AuthAccess.verifyOtp(option);
}

export async function logout(): Promise<{ error?: string }> {
	return AuthAccess.logout();
}

export async function getSession(): Promise<{
	user?: User;
	error?: string;
}> {
	let user: User | undefined;
	let error: string | undefined;

	const { userId, error: e } = await AuthAccess.getSession();
	error = e;
	if (userId && !error) {
		const { data, error: e2 } = await UserAccess.getUser(userId);
		user = data;
		error = e2;
	}

	return { user, error };
}

export function onSessionChange(callback: (result: { user?: User }) => void): {
	unsubscribe: () => void;
} {
	return AuthAccess.onSessionChange(async ({ userId }) => {
		let user: User | undefined;

		if (userId) {
			const { data } = await UserAccess.getUser(userId);
			user = data;
		}

		callback({ user });
	});
}
