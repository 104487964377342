import {create} from "zustand";

interface DialogState {
  showDialog: boolean;
  content: JSX.Element | JSX.Element[] | null | string;
  callback?: ()=>void;
  triggerDialog: (showDialog: boolean) => void;
  show: ({content, callback}: {content: JSX.Element | JSX.Element[] | null | string, callback?: ()=>void}) => void;
}

export const useDialogStore = create<DialogState>()((set) => ({
  showDialog: false,
  content: null,
  show: ({content, callback}) =>  set((state) => ({
	...state,
	content: content,
	callback: callback,
	showDialog: true,
  })),
  triggerDialog: (showDialog) =>
    set(() => ({
      showDialog: showDialog
    })),
}));
