import {useTranslation} from "react-i18next";

const Banner = () => {
  const {t} = useTranslation("generate");
  return (
    <div className="fixed py-3 bg-gradient-to-r from-purple-500 to-pink-400 w-full text-center z-50">
      <div className="container  h-20 sm:h-auto flex justify-center items-center">
        <div
          dangerouslySetInnerHTML={{__html: t("promo") as string}}
          className="text-white"
        ></div>
      </div>
    </div>
  );
};
export default Banner;
