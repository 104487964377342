import {spring} from "@config/animation.config";
import {motion} from "framer-motion";
import {
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import {useCreateStoryStore} from "@store/story.store";
import {
  FilesFormStatus,
  MINIMUM_NO_IMAGE,
} from "../files.form";
import {Button} from "@ui/button";
import {Icons} from "@components/icons";
import {useDialogStore} from "@store/dialog.store";
import ReactCrop, {
  Crop,
  PixelCrop,
  convertToPercentCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import {useDebounceEffect} from "@hooks/useDebaunce";
import { ASPECT_RATIO } from "@lib/image";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "@utils/storage.utils";



const ImageGallery = ({
  characterId,
  setEditingModalSeen,
}: {
  characterId: string;
  setEditingModalSeen: Dispatch<SetStateAction<FilesFormStatus>>;
}) => {
  const {t} = useTranslation("generate");
  const {
    data: story,
    removeImageFromNewImages,
    changeCroppedAreaONewfImage,
    setNewActiveImage,
    addCharacterDetail,
    editCharacterForm,
  } = useCreateStoryStore();
  const {triggerDialog} = useDialogStore((state) => state);
  const minImages = story?.characters?.[characterId]?.character.minFiles || MINIMUM_NO_IMAGE;
  const activeImage = story?.characters?.[characterId]?.currentNewActiveImage;
  const uploadedImages = story?.characters?.[characterId]?.newImages;

  const isMinimumImageValid = uploadedImages
    ? uploadedImages.length >= minImages
    : false;

  
  const handleRemoveImageFromGallery = async (name: string) => {
    removeImageFromNewImages(characterId, name);
  };

  const handleCloseModal = () => {
    if (isMinimumImageValid) {
      addCharacterDetail(characterId, "completed", true);
      setEditingModalSeen("READY");
      const storyImages = useCreateStoryStore.getState().data?.characters?.[characterId].newImages;
      if(storyImages){
         editCharacterForm(
            characterId,
              "files",
              "value",
              JSON.stringify(storyImages)
            );
      }
     
    }
    triggerDialog(false);
  };
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop | undefined>();
  const [_, setCompletedCrop] = useState<PixelCrop>();

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if(activeImage){
        const {width, height} = e.currentTarget;
        setCrop(convertToPercentCrop(activeImage.settings, width, height))

    }
  }
  useDebounceEffect(
    async () => {
      if (
        crop?.width &&
        crop?.height && activeImage
      ) {
        changeCroppedAreaONewfImage(characterId, activeImage?.id, crop);
      }
    },
    100,
    [crop]
  );
  return (
    <>
      <div className="overflow-y-scroll">
        <div className="flex justify-end mt-8 mb-2">
          <Button variant="default" onClick={handleCloseModal}>
            {t("continue")}
          </Button>
        </div>
        <div className="flex justify-center items-center m-auto">
          {!!activeImage && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={ASPECT_RATIO}
              minHeight={150}
            >
              <img
                ref={imgRef}
                className="h-[300px] sm:h-[500px]"
                alt="Crop me"
                src={getFileUrl(activeImage)}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
        <div className="flex flex-wrap justify-center items-center mt-2">
          {uploadedImages &&
            uploadedImages?.map((uploadedImage) => (
              <div
                key={uploadedImage.id}
                onClick={() => {
                  if (activeImage?.id !== uploadedImage.id) {
                    setNewActiveImage(
                      characterId,
                      story?.characters?.[characterId]?.newImages?.find(
                        (imageSetting) => imageSetting.id === uploadedImage.id
                      )
                    );
                  }
                }}
                className="w-12 h-12 relative cursor-pointer mr-4 mb-4 transition ease-in duration-500"
              >
                {activeImage?.id === uploadedImage.id && (
                  <button
                    type="button"
                    className="z-20 absolute inset-0 flex items-center justify-center backdrop-blur-[2px] bg-black/20"
                    onClick={() =>
                      handleRemoveImageFromGallery(uploadedImage.id)
                    }
                  >
                    <Icons.trash className="w-6 h-6 text-secondary" />
                  </button>
                )}
                <img
                  className="rounded-md w-12 h-12 object-cover border-1 shadow-md"
                  src={getFileUrl(uploadedImage)}
                />
                {activeImage?.id === uploadedImage.id && (
                  <motion.div
                    layoutId="layout"
                    className="absolute w-12 h-12 top-0 ring ring-primary ring-offset-4 z-10"
                    initial={false}
                    transition={spring}
                  ></motion.div>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ImageGallery;
