import { cn } from "@lib/utils";
import { motion } from "framer-motion";

const SlideWrapper = ({children, className}: {className?: string | null, children: JSX.Element | JSX.Element[]}) => {
    return (
        <motion.div
        className={cn(className)}
        initial={{ x: -110, opacity: 0}}
        animate={{ x: 0, opacity: 1}}
        exit={{ x: 1000, display: 'none'}}
        transition={{duration: 0.3}}
     >
        {children}
     </motion.div>
      );
}
 
export default SlideWrapper;