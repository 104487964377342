import { User } from "@models/user";
import { create } from "zustand";

interface UserState {
	user?: User | null;
	addUser: (user?: User) => void;
}

export const useCreateUserStore = create<UserState>()((set) => ({
	user: null,
	addUser: (user) => set(() => ({ user: user })),
}));
