import * as BackendStorageAccess from "@access/backend-storage.access";
import * as SupabaseStorageAccess from "@access/supabase-storage.access";
import { JobFile } from "@models/job-file";

export async function uploadFiles(
  provider: 'BACKEND' | 'SUPABASE',
  uploadDir: string,
  files: (File | Blob)[],
  ): Promise<{ files: JobFile[], errors: string[] }> {
  if (provider === 'SUPABASE') {
    return SupabaseStorageAccess.uploadFiles(uploadDir, files);
  } else {
    return BackendStorageAccess.uploadFiles(uploadDir, files);
  }
}
