import {Button} from "@ui/button";
import {Icons} from "@components/icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const GetYourBook = () => {
  const {t} = useTranslation("common");
  return (
    <div className="w-full h-[40vh] flex flex-col items-center justify-center container">
      <h1 className="text-center py-8 text-3xl sm:text-5xl font-bold">
        {t("get_your_book")}
      </h1>
      <Link to="/books">
        <Button className="mt-2 rounded-full fancy-hover p-6">
          <span className="text-llg">{t("upload_images")}</span>
          <Icons.chevronRight />
        </Button>
      </Link>
    </div>
  );
};

export default GetYourBook;
