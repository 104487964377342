import SectionHeader from "@components/section-header";
import Card from "@components/card";
import {useGetCatalogItemsQuery} from "@hooks/useGetCatalogItemsQuery";
import {useTranslation} from "react-i18next";
import {Button} from "@ui/button";
import {Link} from "react-router-dom";
import i18n from "@config/i18n";

const Section2: React.FC = () => {
  const {data: catalogItemsResult} = useGetCatalogItemsQuery({language: i18n.language});
  const {t} = useTranslation(["common", "generate"]);
  const booksToDisplay = catalogItemsResult?.data?.slice(0, 5);
  const hasMoreThanFourBooks =
    catalogItemsResult?.data && catalogItemsResult?.data.length > 5;
  return (
    <div id="coloring_books" className="pt-10">
      <div className="container mb-6">
      <SectionHeader
          linkClass="text-background"
          title={t("landing.choose_theme_header")}
          className="py-2"
        />
        <div className="flex gap-5 snap-x overflow-x-auto pb-4 sm:overflow-x-scroll">
          {booksToDisplay &&
            booksToDisplay?.map((storyItem, index) => (
              <Card key={index} story={storyItem} />
            ))}
          {hasMoreThanFourBooks && (
            <Link to="/books">
              <div className="h-[432px] mt-5 rounded-lg border-2 flex justify-center items-center snap-start scroll-ml-6 shrink-0 relative lg:mt-8 w-64">
              <Button className="text-lg font-bold text-foreground underline underline-offset-8" variant={"link"}>
                {t("view_all", {ns: "generate"})}
              </Button>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section2;
