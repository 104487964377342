import {useGetCatalogItemQuery} from "@hooks/useGetCatalogItemQuery";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import SlideWrapper from "@components/generate/components/slide-wrapper";
import FloatingFooter from "@components/generate/components/floating-footer";
import {Button} from "@ui/button";
import {FORM_TYPE_KEY} from "@pages/generate";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ui/accordion";
import {useForm} from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@ui/form";
import * as z from "zod";
import {useCreateStoryStore} from "@store/story.store";
import {zodResolver} from "@hookform/resolvers/zod";
import {useGetCatalogItemVariationOfItem} from "@hooks/useGetCatalogItemVariationOfItem";
import {MoneyUtils} from "@utils/money.utils";
import {RadioGroup, RadioGroupItem} from "@ui/radio-group";
import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { cn } from "@lib/utils";
import { useScrollToTop } from "@hooks/useScrollToTop";

const BookOverview = () => {
  useScrollToTop();
  const params = useParams();
 const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation("generate");
  const {data: catalogItem} = useGetCatalogItemQuery(params?.itemId || "");
  const {data: variations} = useGetCatalogItemVariationOfItem(
    params?.itemId || ""
  );
  const location = useLocation();

  const {updateForm, data: story, resetStory} = useCreateStoryStore((state) => state);
  useEffect(()=>{
    if( story.currentActiveBook && params?.itemId !== story.currentActiveBook ){
      resetStory();
    }
  }, [location, story])


  let [, setSearchParams] = useSearchParams();
  const formSchema = z.object({
    priceVariationsId: z.string({
      required_error: "Please select book type",
    }),
  });
  const defaultValues: Partial<WhatToExpectFormValues> = {
    priceVariationsId: variations?.data?.[0].id
  };
  type WhatToExpectFormValues = z.infer<typeof formSchema>;
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues
  });
  const priceVariationsId = form.watch("priceVariationsId");
  // Select the first price variation all the time
  useEffect(() => {
    if (variations) {
      form.reset(defaultValues);
    }
  }, [variations]);
  const acceptTermsHandler = (data: WhatToExpectFormValues) => {
    if (variations && variations.data) {
      updateForm({
        priceVariation: variations.data.find(
          (v) => v.id === data.priceVariationsId
        ),
        currentActiveBook: params.itemId
      });
      setSearchParams({[FORM_TYPE_KEY]: "1"});
    }
  };

  return (
    <SlideWrapper className="mt-14">
      <>
        {catalogItem?.data?.catalog_images.length === 1 && (
          <img
            className="h-96 border-2 m-auto rounded-lg object-cover object-center"
            src={catalogItem?.data?.catalog_images[0].url}
            alt=""
          />
        )}
        {catalogItem?.data?.catalog_images &&
          catalogItem?.data?.catalog_images?.length > 1 && (
            <div className="w-full flex gap-3 snap-x overflow-x-auto pb-6">
              {catalogItem?.data?.catalog_images.map((item, index) => (
                <div
                  key={index}
                  className="snap-start scroll-ml-6 shrink-0 relative first:pl-3 last:pr-3 cursor-pointer"
                >
                  <div className="w-6 absolute top-0 left-0 bottom-0"></div>
                  <img
                    className="relative shrink-0 border-2 object-cover h-96 rounded-lg shadow-lg"
                    src={item.url}
                    alt={`Catalog Image ${index}`}
                  />
                </div>
              ))}
            </div>
          )}
      </>
      <div className="container">
        <div className="text-2xl font-bold mt-4 mb-4 line-clamp-4">{catalogItem?.data?.name}</div>
        <div className={cn("mb-2", showMore ? "" : "line-clamp-4") }>{catalogItem?.data?.descriptionPlaintext} </div>
   
        {catalogItem?.data && catalogItem.data.descriptionPlaintext!.length > 200 && <span onClick={()=>setShowMore(!showMore)} className="text-primary cursor-pointer sm:hidden inline-block">{showMore ? "less" : "more"} </span>}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(acceptTermsHandler)}>
            {variations?.data && priceVariationsId && <div className="my-4">
              <FormField
                control={form.control}
                name="priceVariationsId"
                render={({field}) => (
                  <FormItem className="space-y-1">
                    {variations?.data && variations?.data?.length > 1 && <FormLabel className="font-bold text-lg">{t('select_one')}</FormLabel>}
                    <FormMessage />
                    <RadioGroup
                      onValueChange={(e) => {
                        field.onChange(e);
                      }}
                      defaultValue={field.value }
                      className="grid max-w-md grid-cols-2 gap-8 pt-2"
                      >
                      {variations?.data?.map((variant) => {
                        return (
                          <FormItem key={variant.id}>
                            <FormLabel className={cn("[&:has([data-state=checked])>div]:border-primary")}>
                              <FormControl>
                                <RadioGroupItem
                                  value={variant.id}
                                  className="sr-only"
                                />
                              </FormControl>
                              <div className="items-center rounded-md border-2 space-y-3 border-muted p-1 hover:border-accent">
                                <div className="text-center font-bold">
                                  {variant.name}
                                </div>
                                <span className="block w-full text-center font-normal">
                                <span className="font-bold">
                                 {variant.priceMoney &&
                                    MoneyUtils.showMoney(
                                      variant.priceMoney,
                                      true
                                    )}
                                </span>
                              </span>
                              {/* just dummy declare on after the compliation the class would be added so later based on the condition on line 134 will work */}
                                <div className="text-center [&:has([data-state=checked])>div]:border-primary">
                                  {/* @ts-ignore */}
                                <div className="space-y-1" dangerouslySetInnerHTML={{__html: variant.description}}></div>
                                </div>
                              </div>
                             
                            </FormLabel>
                          </FormItem>
                        );
                      })}
                    </RadioGroup>
                  </FormItem>
                )}
              />
            </div>
}
            <Accordion type="single" collapsible className="w-full pb-40">
              <AccordionItem value="item-1">
                <AccordionTrigger>{t("format_quality")}</AccordionTrigger>
                <AccordionContent>
                  <p>{t("format_quality_pages")}</p>
                  <ol className="list-disc list-inside mt-2">
                    <li>{t("prices")}</li>
                    <li>{t("prices_description")}</li>
                    <li>30 {t("pages")}</li>
                    <li>9 x 9 {t("size")}</li>
                  </ol>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger>{t("what_to_expect")}</AccordionTrigger>
                <AccordionContent>
                 {t("shipment_description")}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <FloatingFooter className="flex justify-center">
              <Button
                variant="default"
                className="w-full sm:w-72"
                type="submit"
              >
                {t("personalize")}
              </Button>
            </FloatingFooter>
          </form>
        </Form>
      </div>
    </SlideWrapper>
  );
};

export default BookOverview;
