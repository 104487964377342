import {Separator} from "@ui/separator";
import {useToast} from "@ui/use-toast";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {zodResolver} from "@hookform/resolvers/zod";
import * as z from "zod";
import {Form, FormControl, FormField, FormItem, FormMessage} from "@ui/form";
import * as Auth from "@services/auth.service";
import {Button} from "@ui/button";
import {Icons} from "@components/icons";
import {Input} from "@ui/input";
import {useCreateEmailStore} from "@store/email.store";
import {useDialogStore} from "@store/dialog.store";
import {useCreateUserStore} from "@store/auth.store";
import {User} from "@models/user";

const VerifyFormModal = ({redirectTo}:{redirectTo?: string}) => {
  const {triggerDialog} = useDialogStore((state) => state);
  const {addUser} = useCreateUserStore((state) => state);
  const {email, setEmail} = useCreateEmailStore();
  const [otpState, setOtpState] = useState<{
    error: string;
    status: "idle" | "success" | "loading" | "failed";
  }>({error: "", status: "idle"});
  const [retrySentCodeToState, setRetrySentCodeToState] = useState<{
    error: string;
    status: "idle" | "success" | "loading" | "failed";
  }>({error: "", status: "idle"});
  const {toast} = useToast();
  const {t} = useTranslation("common");

  const verificationFormSchema = z.object({
    token: z.string(),
  });

  const verificationForm = useForm<z.infer<typeof verificationFormSchema>>({
    resolver: zodResolver(verificationFormSchema),
    defaultValues: {
      token: "",
    },
  });
  const handleOtp = async ({token}: z.infer<typeof verificationFormSchema>) => {
    if (!email) {
      return;
    }
    setOtpState({...otpState, status: "loading"});
    const {error, data} = await Auth.verifyOtp({
      token: token,
      email,
      type: "email",
      redirectTo: redirectTo,
    });

    if (error) {
      toast({
        variant: "destructive",
        description: t("login.error_failed_message") ?? error,
      });
      setOtpState({...otpState, status: "failed", error: error});
      return;
    }
    setOtpState({...otpState, status: "success"});
    setEmail("");
    if (data) {
      triggerDialog(false);
      addUser(data as User);
      if(redirectTo){
        window.location.href = redirectTo
      }
    }
  };

  const retrySentCodeTo = async () => {
    if (!email) {
      return;
    }
    setRetrySentCodeToState({...retrySentCodeToState, status: "loading"});
    const {error} = await Auth.signInWithOtp({email, redirectTo});

    if (error) {
      toast({
        variant: "destructive",
        description: error ?? t("login.error_failed_message"),
      });
      setRetrySentCodeToState({
        ...retrySentCodeToState,
        status: "failed",
        error: error,
      });
      return;
    }
    toast({
      description: `Code has been sent to ${email}`,
    });
    setRetrySentCodeToState({...retrySentCodeToState, status: "success"});
  };
  return (
    <div>
      <Button
        variant={"outline"}
        className="absolute top-10"
        onClick={() => setEmail("")}
      >
        Back
      </Button>
      <div className="text-center text-sm mb-2">
      {t("email_has_been_sent_to")} {email}{" "}
      </div>
      <Form {...verificationForm}>
        <form
          onSubmit={verificationForm.handleSubmit(handleOtp)}
          className="space-y-4"
        >
          <FormField
            control={verificationForm.control}
            name="token"
            render={({field}) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Enter your code" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            className="my-4 w-full"
            type="submit"
            disabled={otpState.status === "loading"}
          >
            {t("submit")}
            {otpState.status === "loading" && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
          </Button>
          <Separator />
          <Button
            className="my-4 w-full"
            variant={"outline"}
            onClick={retrySentCodeTo}
            disabled={retrySentCodeToState.status === "loading"}
          >
            {t("didnt_recive_code")}
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default VerifyFormModal;
