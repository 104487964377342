import { JobFile } from "@models/job-file";
import axios from "axios";

export async function uploadFiles(
  _uploadDir: string,
  files: (File | Blob)[],
): Promise<{ files: JobFile[], errors: string[] }> {
  let response: { files: JobFile[], errors: string[] } | undefined;
  let errorMessage: string | undefined;

  try {
    const baseUrl = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;

    const formData = new FormData();
    for (const file of files) {
      formData.append("data", file);
    }

    const { data } = await axios.post(baseUrl + "/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    response = data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      errorMessage = error.message;
    } else {
      errorMessage = "Failed to place order: " + error;
    }
  }

  return errorMessage || !response ?
    {
      files: [],
      errors: [errorMessage || ''],
    } :
    response;
}
