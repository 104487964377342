import {Icons} from "@components/icons";
import {Input} from "@components/ui/input";
import {zodResolver} from "@hookform/resolvers/zod";
import * as Auth from "@services/auth.service";
import {useCreateEmailStore} from "@store/email.store";
import {Button} from "@ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@ui/form";
import {useToast} from "@ui/use-toast";
import {isSafari} from "@utils/isSafari";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link, useSearchParams} from "react-router-dom";

import * as z from "zod";

const SignInForm = ({
  redirectTo,
  bottomSheet,
}: {
  redirectTo?: string;
  bottomSheet?: boolean;
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const typeOfAuth =
    (searchParams.get("typeOfAuth") as "with-mail" | "regular") || "regular";
  const {t} = useTranslation(["common", "generate"]);
  const {setEmail} = useCreateEmailStore();
  const {toast} = useToast();
  const [loginState, setLoginState] = useState<{
    error: string;
    status: "idle" | "success" | "loading" | "failed";
  }>({error: "", status: "idle"});
  const formSchema = z.object({
    email: z.string().email(t("login.email_invalid") ?? ""),
    password:
      typeOfAuth === "regular"
        ? z.string().min(6, t("login.password_invalid") as string)
        : z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  async function onSubmit({email, password}: z.infer<typeof formSchema>) {
    setLoginState({...loginState, status: "loading"});
    let errorFromApi: string | undefined;
    if (typeOfAuth === "regular" && password) {
      const {error} = await Auth.login({email, password});
      errorFromApi = error;
    } else {
      const {error} = await Auth.signInWithOtp({email, redirectTo});
      toast({
        description: `Code has been sent to ${email}`,
      });
      errorFromApi = error;
    }

    if (errorFromApi) {
      toast({
        variant: "destructive",
        description: t("login.error_failed_message") ?? errorFromApi,
      });
      setLoginState({...loginState, status: "failed", error: errorFromApi});
      return;
    }
    setLoginState({...loginState, status: "success"});
    if(typeOfAuth === "with-mail"){
      setEmail(email);
    }else{
      window.location.href = redirectTo ?? "/";
    }
  }

  return (
    <>
      <div className="flex flex-col space-y-4 text-center">
        {!bottomSheet ? (
          <>
            <h1 className="text-2xl font-semibold tracking-tight">
              {t("login.login_account_header")}
            </h1>
            <p className="text-sm text-muted-foreground">
              {t("login.login_account_description")}
            </p>
          </>
        ) : (
          <>
            <h1 className="text-2xl font-semibold tracking-tight">
              {t("login.login_account_header_bottom_sheet")}
            </h1>
            <p className="text-sm text-muted-foreground">
              {t("login.login_account_header_bottom_sheet_desc")}
            </p>
          </>
        )}
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          {typeOfAuth === "regular" ? (
            <>
              <FormField
                control={form.control}
                name="email"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>{t("login.email")}</FormLabel>
                    <FormControl>
                      <Input placeholder="john.doe@doe.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>{t("login.password")}</FormLabel>
                    <FormControl>
                      <Input
                        type="password"
                        placeholder="*********"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          ) : (
            <FormField
              control={form.control}
              name="email"
              render={({field}) => (
                <FormItem>
                  <FormLabel>{t("login.email")}</FormLabel>
                  <FormControl>
                    <Input placeholder="john.doe@doe.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <Button
            className="my-4 w-full"
            type="submit"
            disabled={loginState.status === "loading"}
          >
            {t("continue", {ns: "generate"})}
            {loginState.status === "loading" && (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
          </Button>
          <span className="text-xs flex justify-end text-right ml-auto">
            <Link to="/sign-up" state={{redirectUrl: redirectTo}}> {t("login.no_account_sign_up_here")}</Link>
          </span>
        </form>
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-muted-foreground">
              {t("login.create_account_or_continue_with")}
            </span>
          </div>
        </div>
      </Form>

      <div className="social flex flex-col space-y-2 mt-4">
        <Button
          className="w-full bg-white border"
          type="button"
          variant="secondary"
          onClick={async () =>
            await Auth.signWithSocialLogin({
              provider: "google",
              redirectTo: redirectTo,
            })
          }
        >
          <Icons.googleColor className="mr-2 h-4 w-4" />
          {t("login.sign_in_with")} Google
        </Button>

        {isSafari() ? (
          <Button
            className="w-full bg-white border"
            type="button"
            variant="secondary"
            onClick={async () =>
              await Auth.signWithSocialLogin({
                provider: "apple",
                redirectTo: redirectTo,
              })
            }
          >
            <Icons.apple className="mr-2 h-4 w-4" />
            {t("login.sign_in_with")} Apple
          </Button>
        ) : null}
        {typeOfAuth === "regular" && (
          <Button
            className="w-full bg-white border"
            type="button"
            variant="secondary"
            onClick={() => {
              setSearchParams({typeOfAuth: "with-mail"});
            }}
          >
            <Icons.mail className="mr-2 h-4 w-4" />
            {t("login.sign_in_with")} Mail
          </Button>
        )}
      </div>
    </>
  );
};

export default SignInForm;
