import { useEffect } from "react";
import * as AuthService from "@services/auth.service";
import { useCreateUserStore } from "@store/auth.store";
import { User } from "@models/user";
const useUser = () => {
    const user = useCreateUserStore((state) => state.user);
	const addUser = useCreateUserStore((state) => state.addUser);
    useEffect(() => {
		AuthService.getSession().then(({ user }) => {
			addUser(user);
		});

		const { unsubscribe } = AuthService.onSessionChange((result) => {
			addUser(result.user as unknown as User);
		});

		return () => unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    return {
        user
    };
}
 
export default useUser;