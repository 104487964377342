import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {persist, createJSONStorage, StateStorage} from "zustand/middleware";

import {CatalogItemVariation} from "@models/catalog-item-variation";
import {Crop, PercentCrop} from "react-image-crop";
import {CustomCharacter} from "@models/custom-character";

type CharacterProperty =
  | "completed"
  | "newImages";

type FormProperty = "status" | "value";

export type FormValue = "age" | "gender" | "files";

export type FormStage = {
  name: FormValue;
  status: boolean;
  value: string | string[] | null;
};
export interface ImageContent {
  id: string;
  path: string;
  provider: string;
  settings: PercentCrop;
}

export interface CharacterUI {
  character: CustomCharacter;
  image?: string;
  completed: boolean;
  currentNewActiveImage: ImageContent | null;
  newImages?: ImageContent[];
  formStages: FormStage[] | null;
  formStatusQuo: FormValue[] | null;
}

interface Story {
  id?: string;
  title?: string;
  description?: string;
  characters?: Record<string, CharacterUI> | null;
  activeSelectedCharacterId?: string;
  priceVariation?: CatalogItemVariation;
  acceptedTerm: boolean;
  currentActiveBook?: string;
}
const initialStoryState: Story = {
  characters: null,
  acceptedTerm: false,
};

export interface StoryState {
  data: Story;
  updateForm: (data: Record<string, any>) => void;
  resetFormStatusQuo: (characterId: string) => void;
  addCharacters: (characters: Record<string, CharacterUI>) => void;
  setActiveSelectedCharacterId: (characterId: string) => void;
  addCharacterDetail: (
    characterId: string,
    property: CharacterProperty,
    value: CharacterUI[CharacterProperty]
  ) => void;
  editCharacterForm: (
    characterId: string,
    formName: FormValue,
    property: FormProperty,
    value: FormStage[FormProperty]
  ) => void;
  setNewActiveImage: (characterId: string, activeImage?: ImageContent) => void;
  removeImageFromNewImages: (characterId: string, imageId: string) => void;
  resetStory: () => void;
  changeCroppedAreaONewfImage: (
    characterId: string,
    imageId: string,
    croppedArea: Crop
  ) => void;
}

export const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return localStorage.getItem(name) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    localStorage.setItem(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    localStorage.removeItem(name);
  },
};
export const useCreateStoryStore = create(
  persist(
    immer<StoryState>((set) => ({
      data: initialStoryState,
      resetStory: () =>
        set(() => {
          return {data: initialStoryState};
        }),
      updateForm: (data) =>
        set((state) => {
          return {data: {...state.data, ...data}};
        }),
      setNewActiveImage: (characterId, activeImage) =>
        set((state) => {
          const firstImageFromArray =
            //@ts-ignore
            state.data.characters[characterId].newImages?.[0];

          if (typeof activeImage === "undefined") {
            //@ts-ignore
            state.data.characters[characterId].currentNewActiveImage =
              firstImageFromArray;
          }
          // @ts-ignore
          state.data.characters[characterId].currentNewActiveImage =
            activeImage;
        }),
      setActiveSelectedCharacterId: (characterId) =>
        set((state) => {
          state.data.activeSelectedCharacterId =
            state?.data?.characters?.[characterId].character.id;
        }),
      addCharacters: (characters: Record<string, CharacterUI>) =>
        set((state) => {
          state.data.characters = characters;
        }),
      addCharacterDetail: (characterId, property, value) =>
        set((state) => {
          //@ts-ignore
          state.data.characters[characterId][property] = value;
          if (property === "completed") {
            // set setActiveSelectedCharacterId the next character that has the status completed to false
            state.data.activeSelectedCharacterId = Object.values(
              // @ts-ignore
              state?.data.characters
            ).find((character) => !character.completed)?.character.id;
          }
        }),
      resetFormStatusQuo: (characterId) =>
        set((state) => {
          //@ts-ignore
          state.data.characters[characterId].formStatusQuo =
            state.data.characters?.[characterId]?.formStages?.map(
              (form) => form.name
            );
        }),
      changeCroppedAreaONewfImage: (characterId, imageId, croppedArea) =>
        set((state) => {
          const imageIndexFromUploadedImages = state.data.characters?.[
            characterId
          ].newImages?.findIndex((el) => el.id === imageId);
          // @ts-ignore
          state.data.characters[characterId].newImages[
            // @ts-ignore
            imageIndexFromUploadedImages
          ].settings = croppedArea;
        }),

      removeImageFromNewImages: (characterId, imageId) =>
        set((state) => {
          const imageIndexFromUploadedImages = state.data.characters?.[
            characterId
          ].newImages?.findIndex((el) => el.id === imageId);
          //@ts-ignore
          state.data.characters[characterId].newImages?.splice(
            //@ts-ignore
            imageIndexFromUploadedImages,
            1
          );
        }),
      editCharacterForm: (characterId, formName, property, value) =>
        set((state) => {
          const formIndex = state.data.characters?.[
            characterId
          ].formStages?.findIndex((el) => el.name === formName);
          if (formIndex && formIndex === -1) {
            return;
          }
          // @ts-ignore
          state.data.characters[characterId].formStages[formIndex][property] =
            value;
          // @ts-ignore
          state.data.characters[characterId].formStages[formIndex]["status"] =
            true;
          const formsStatusQuoList =
            state.data.characters?.[characterId].formStatusQuo;
          // check if the its the last for then do not remove it from the list
          if (formsStatusQuoList && formsStatusQuoList.length > 1) {
            // @ts-ignore
            const indexOfFormStatusQuo =
              state.data.characters?.[characterId].formStatusQuo?.indexOf(
                formName
              ) ?? 0;
            // @ts-ignore
            state.data.characters?.[characterId].formStatusQuo?.splice(
              indexOfFormStatusQuo,
              1
            );
          }
          // check every formStages and see if their status is set to true
          const statusOfAllForms = state?.data?.characters?.[
            characterId
          ]?.formStages
            ?.map((form) => form.status)
            .every((value) => value === true);
          // @ts-ignore
          state.data.characters[characterId]!.completed = statusOfAllForms;
          // @ts-ignore
          state.data.activeSelectedCharacterId = Object.values(
            // @ts-ignore
            state?.data.characters
          ).find((character) => !character.completed)?.character.id;
        }),
    })),
    {name: "story", storage: createJSONStorage(() => storage)}
  )
);
