
export function toSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function toCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (matchedLetter) =>
    matchedLetter[1].toUpperCase()
  );
}

/**
 * Deep conversion of keys of an object or array to snake case.
 */
export function toSnakeCaseJson(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  let result: any;

  if (obj.constructor === Array) {
    result = [];
    for (const element of obj) {
      result.push(toSnakeCaseJson(element));
    }
  } else {
    result = {};
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = toSnakeCase(key);
        result[snakeCaseKey] = toSnakeCaseJson(obj[key]);
      }
    }
  }

  return result;
}

/**
 * Deep conversion of keys of an object or array to camel case.
 */
export function toCamelCaseJson(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  let result: any;

  if (obj.constructor === Array) {
    result = [];
    for (const element of obj) {
      result.push(toCamelCaseJson(element));
    }
  } else {
    result = {};
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = toCamelCase(key);
        result[snakeCaseKey] = toCamelCaseJson(obj[key]);
      }
    }
  }

  return result;
}

export function generateRandomPassword(length: number): string {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=";
  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
}
