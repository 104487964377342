import "@src/styles/landing.css";
import Section1 from "@components/landing/section-1";
import Section2 from "@components/landing/section-2";
import Section4 from "@components/landing/section-4";
import Faq from "@components/landing/faq";
import SectionCarousel from "@components/landing/sectiom-carousel";
import Steps from "@components/landing/steps";
import Privacy from "@components/landing/privacy";
import GetYourBook from "@components/landing/get-your-book";
import Analitics from "@components/landing/analitics";


const LandingPage = () => {

  return (
    <div className="">
        <Section1 />
        <SectionCarousel />
        <Steps/>
        <Privacy />
        <Section2 />
        <Analitics />
        <Section4 />
        <Faq />
        <GetYourBook />
    </div>
  );
};

export default LandingPage;
