import {motion} from "framer-motion";
import {spring} from "@config/animation.config";
import {Icons} from "@components/icons";
import {useCreateStoryStore} from "@store/story.store";
import {CustomCharacter, CustomPersonGender} from "@models/custom-character";

export function Character({
  isSelected,
  onClick,
  character,
}: {
  isSelected: boolean;
  onClick: any;
  character: CustomCharacter;
}) {
  const {data: story} = useCreateStoryStore((state) => state);
  const customGenderValue = story?.characters?.[character.id]?.character.gender ;
  const characterCompleted = story?.characters?.[character.id]?.completed;
  const name = story?.characters?.[character.id]?.character.name;
  const image = story?.characters?.[character.id]?.image;
  return (
    <div
      onClick={onClick}
      className="character-card w-[calc(50%-0.5rem)] transition ease-in duration-500 flex flex-col justify-center items-center relative cursor-pointer"
    >
      {image ? (
        <img
          className="w-32 h-32 rounded-full shadow-lg object-cover"
          src={image}
        />
      ) : customGenderValue === CustomPersonGender.FEMALE ? (
        <Icons.girl className="w-32 h-32 fill-primary girl" />
      ) : customGenderValue === CustomPersonGender.MALE ? (
        <Icons.boy className="w-32 h-32 fill-primary" />
      ) : (
        <Icons.questionMarkCircle className="w-32 h-32 text-primary" />
      )}
      {characterCompleted && (
        <div className="character-completed-check absolute top-0 rounded-full w-44 h-32 backdrop-blur-sm bg-white/10 z-20">
          <Icons.circleCheck className="w-full h-full text-primary" />
        </div>
      )}
      <div className="character-name text-center mt-3">{name || ''}</div>
      {isSelected && (
        <motion.div
          layoutId="outline"
          className="character-outline-active absolute rounded-full w-32 h-32 md:w-40 md:h-40 top-0 ring ring-primary ring-offset-4"
          initial={false}
          transition={spring}
        ></motion.div>
      )}
    </div>
  );
}
