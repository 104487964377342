import {CatalogItem} from "@models/catalog-item";
import {CatalogItemVariation} from "@models/catalog-item-variation";
import {Order, OrderState} from "@models/order";
import {FulfillmentState, FulfillmentType} from "@models/order-fulfillment";
import {OrderLineItem, OrderLineItemItemType} from "@models/order-line-item";
import {User} from "@models/user";
import {CharacterUI, useCreateStoryStore} from "@store/story.store";
import {MoneyUtils} from "@utils/money.utils";
import * as OrderService from "@services/order.service";
import {useCreateUserStore} from "@store/auth.store";
import {CatalogItemWithJobFlowTemplate} from "@typesClient/story";

export enum OrderStatusEnum {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
const useCreateOrder = () => {
  const user = useCreateUserStore((state) => state.user) as User;

  const initOrder = (
    user: User
  ): Omit<Order, "id" | "createdAt" | "updatedAt"> => {
    return {
      userId: user.id,
      userLocales: navigator.languages === undefined
        ? [navigator.language]
        : [...navigator.languages],
      state: OrderState.OPEN,
      lineItems: [],
      fulfillments: [
        {
          type: FulfillmentType.DIGITAL,
          state: FulfillmentState.PROPOSED,
          emailAddress: user.email,
        },
      ],
      subtotalMoney: {amount: 0, currency: "EUR"},
      totalMoney: {amount: 0, currency: "EUR"},
    };
  };

  const addItemToOrder = (
    order: Omit<Order, "id" | "createdAt" | "updatedAt">,
    catalogItem: CatalogItem,
    catalogItemVariation: CatalogItemVariation,
    metadata: { [key: string]: string },
  ): Omit<Order, "id" | "createdAt" | "updatedAt"> => {
    const availableFulfillmentTypes: FulfillmentType[] = [];
    if (catalogItem.availableForPickup) availableFulfillmentTypes.push(FulfillmentType.PICKUP);
    if (catalogItem.availableForShipment) availableFulfillmentTypes.push(FulfillmentType.SHIPMENT);
    if (catalogItem.availableElectronically) availableFulfillmentTypes.push(FulfillmentType.DIGITAL);

    const orderLineItem: OrderLineItem = {
      catalogItemVariationId: catalogItemVariation?.id,
      name: catalogItem.name,
      variationName: catalogItemVariation?.name,
      quantity: 1,
      itemType: OrderLineItemItemType.ITEM_PERSONALIZED,
      availableFulfillmentTypes,
      basePriceMoney: catalogItemVariation?.priceMoney || {
        amount: 0,
        currency: "EUR",
      },
      grossSalesMoney: catalogItemVariation?.priceMoney || {
        amount: 0,
        currency: "EUR",
      },
      variationTotalPriceMoney: {amount: 0, currency: "EUR"},
      modifierTotalPriceMoney: {amount: 0, currency: "EUR"},
      totalDiscountMoney: {amount: 0, currency: "EUR"},
      totalTaxMoney: {amount: 0, currency: "EUR"},
      totalMoney: catalogItemVariation?.priceMoney || {
        amount: 0,
        currency: "EUR",
      },
      metadata,
    };

    order.lineItems.push(orderLineItem);
    order.subtotalMoney = MoneyUtils.add(
      order.subtotalMoney,
      orderLineItem.totalMoney
    );
    order.totalMoney = MoneyUtils.add(
      order.totalMoney,
      orderLineItem.totalMoney
    );

    return order;
  };

  const handleCreateOrder = async (
    catalogItem:
      | {data?: CatalogItemWithJobFlowTemplate; error?: string}
      | undefined
  ): Promise<{status: OrderStatusEnum, order?: Order}> => {
    const characters: { [key: string]: any }[] = [];

    const characterUIs = useCreateStoryStore.getState().data.characters as Record<string, CharacterUI>;
    Object.values(characterUIs).forEach((characterUI) => {
      const jobFlowCharacter: { [key: string]: any } = JSON.parse(JSON.stringify(characterUI.character));
      characterUI.formStages?.forEach((stage) => jobFlowCharacter[stage.name] = stage.value || undefined);
      characters.push(jobFlowCharacter);
    });

    const order = initOrder(user);
    if (catalogItem?.data) {
      addItemToOrder(
        order,
        catalogItem?.data,
        // @ts-ignore
        useCreateStoryStore.getState().data.priceVariation,
        {
          jobFlow: {
            jobFlowTemplateId: catalogItem.data.jobFlowTemplateId,
            characters,
          },
        },
      );
    }

    const { data, error} = await OrderService.placeOrder(order);
    if (error && !data) {
      return { status: OrderStatusEnum.FAILED };
    }
    return {status: OrderStatusEnum.SUCCESS, order: data} ;
  };
  return {
    handleCreateOrder: handleCreateOrder,
  };
};

export default useCreateOrder;
