import {centerCrop, makeAspectCrop} from "react-image-crop";

export const ASPECT_RATIO = 1 / 1;

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number) {
  return {
    width: Math.abs(width) + Math.abs(height),
    height: Math.abs(width) + Math.abs(height),
  };
}


export function getImageDimensions(
  file: File
): Promise<{width: number; height: number}> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();

      img.onload = () => {
        resolve({width: img.width, height: img.height});
      };

      img.onerror = () => {
        reject(new Error("Unable to get image dimensions."));
      };

      img.src = event.target?.result as string;
    };

    reader.onerror = () => {
      reject(new Error("Failed to read the file."));
    };

    reader.readAsDataURL(file);
  });
}

export function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}


export function compressImage(file: File, quality: number, meetsSizeRequirement: boolean): Promise<File> {
  return new Promise((resolve, reject) => {
    if(!meetsSizeRequirement){
      resolve(file);
    }
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          const compressedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        } else {
          reject(new Error('Failed to compress image.'));
        }
      }, file.type, quality);
    };

    image.onerror = () => {
      reject(new Error('Failed to load image.'));
    };
  });
}