// @ts-ignore
import {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@src/styles/review.css";
import SectionHeader from "@components/section-header";
import { useTranslation } from "react-i18next";
import review1En from "@assets/images/review/1-en.webp"
import review1De from "@assets/images/review/1-de.webp"
import review2En from "@assets/images/review/2-en.webp"
import review2De from "@assets/images/review/2-de.webp"
import review3En from "@assets/images/review/3-en.webp"
import review3De from "@assets/images/review/3-de.webp"

import review1EnM from "@assets/images/review/1-en-m.webp";
import review1DeM from "@assets/images/review/1-de-m.webp";
import review2EnM from "@assets/images/review/2-en-m.webp";
import review2DeM from "@assets/images/review/2-de-m.webp";
import review3EnM from "@assets/images/review/3-en-m.webp";
import review3DeM from "@assets/images/review/3-de-m.webp";
import { useEffect, useState } from "react";


const Section4 = () => {
  const reviewSlidesEng = [
    {
      image: review1En,
      imageMobile: review1EnM,
    },
    {
      image: review2En,
      imageMobile: review2EnM,
    },
    {
      image: review3En,
      imageMobile: review3EnM,
    },
];
const reviewSlidesDe = [
    {
      image: review1De,
      imageMobile: review1DeM,
    },
    {
      image: review2De,
      imageMobile: review2DeM,
    },
    {
      image: review3De,
      imageMobile: review3DeM,
    },
];
  const [slidesImages, setSlidesImages] = useState(reviewSlidesEng)
  const {t, i18n} = useTranslation('common');
  const pagination = {
    clickable: true,
    dynamicBullets: true,
  };
  // check the languge and set the slides images accordingly
  const handleLangChange = (lang: string) => {
  
    if (lang === 'de') {
      setSlidesImages(reviewSlidesDe)
    } else {
      setSlidesImages(reviewSlidesEng)
    }
  }
  useEffect(()=>{
    handleLangChange(i18n.language)
  }, [t])
  return (
    <div id="">
       <SectionHeader className="container text-center flex justify-center mb-2" title={t("landing.hear_from_customers")}/>
      <div className="flex h-full container">
        <Swiper
          modules={[Autoplay,Pagination]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={pagination}
          className="review-slider"
        >
          {slidesImages.map(({image, imageMobile}, index) => {
            return (
              <SwiperSlide className="w-full" key={index}>
                    <img
                      src={image}
                      className="hidden sm:block w-full h-[500px] object-cover rounded-lg object-left image-item"
                      alt="welcome-slide"
                    />
                    <img
                      src={imageMobile}
                      className="block sm:hidden w-full object-cover rounded-lg"
                      alt="welcome-slide"
                    />
              
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>

  );
};

export default Section4;
