import { languages } from "@config/i18n";
import { Button } from "@ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import {
	Globe,
} from "lucide-react";
import { useTranslation } from "react-i18next";

export function LanguageDropdown() {
	const { i18n } = useTranslation("common");
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="relative h-8 w-8 mx-2 rounded-full">
					<Globe className="absolute h-4 w-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuGroup>
					{languages.map(({ label, key }) => (
						<DropdownMenuItem key={key} onClick={() => changeLanguage(key)}>
							{label}
						</DropdownMenuItem>
					))}
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
