import { supabase } from "@config/supabaseClient";
import { CatalogItem, CatalogItemWithImages } from "@models/catalog-item";
import { CatalogItemVariation } from "@models/catalog-item-variation";
import { CatalogModifierWithImages } from "@models/catalog-modifier";
import { CatalogModifierList } from "@models/catalog-modifier-list.ts";


export async function getCatalogItem(
	itemId: string
): Promise<{ data?: CatalogItem; error?: string }> {
	const { data, error } = await supabase
		.from("catalog_items")
		.select("*, catalog_images ( id, name, url )")
		.eq("id", itemId)
		.order("ordinal", { foreignTable: 'catalog_images', ascending: true })
		.maybeSingle();
	return {
		data: data ? (data as CatalogItem) : undefined,
		error: error?.message,
	};
}

export async function getAllCatalogItems(): Promise<{
	data?: CatalogItemWithImages[];
	error?: string;
}> {
	const { data, error } = await supabase
		.from("catalog_items")
		.select(`
			id,
			categoryId,
			taxIds,
			name,
			descriptionPlaintext,
			modifierListInfos,
			availableForPickup,
			availableForShipment,
			availableElectronically,
			visibility,
			createdAt,
			updatedAt,
			locale,
			catalog_images ( id, name, url )
		`)
    	.eq("visibility", "VISIBLE")
		.order("ordinal", { foreignTable: 'catalog_images', ascending: true });
	return {
		data: data ? (data as CatalogItemWithImages[]) : [],
		error: error?.message,
	};
}

export async function getCatalogItemVariationsOfItem(
	itemId: string
): Promise<{ data?: CatalogItemVariation[]; error?: string }> {
	const { data, error } = await supabase
		.from("catalog_item_variations")
		.select()
		.eq("itemId", itemId);
	return {
		data: data ? (data as CatalogItemVariation[]) : [],
		error: error?.message,
	};
}

export async function getCatalogModifierList(
	modifierListId: string
): Promise<{ data?: CatalogModifierList; error?: string }> {
	const { data, error } = await supabase
		.from("catalog_modifier_lists")
		.select()
		.eq("id", modifierListId)
		.maybeSingle();
	return {
		data: data ? (data as CatalogModifierList) : undefined,
		error: error?.message,
	};
}

export async function getCatalogModifiersOfList(
	modifierListId: string
): Promise<{ data?: CatalogModifierWithImages[]; error?: string }> {
	const { data, error } = await supabase
		.from("catalog_modifiers")
		.select("*, catalog_images ( id, name, url )")
		.eq("modifierListId", modifierListId)
		.order("ordinal", { foreignTable: 'catalog_images', ascending: true });
	return {
		data: data ? (data as CatalogModifierWithImages[]) : [],
		error: error?.message,
	};
}
export async function getCatalogModifiersWithIds(
	modifierListIds: string[]
): Promise<{ data?: CatalogModifierWithImages[]; error?: string }> {
	const { data, error } = await supabase
		.from("catalog_modifiers")
		.select("*, catalog_images ( id, name, url )")
		.in("id", modifierListIds)
		.order("ordinal", { foreignTable: 'catalog_images', ascending: true });

	return {
		data: data ? (data as CatalogModifierWithImages[]) : [],
		error: error?.message,
	};
}


