import { supabase } from "@config/supabaseClient";
import { JobFlowTemplate } from "@models/job-flow-template";


export async function getJobFlowTemplate(
	templateId: string
): Promise<{ data?: JobFlowTemplate; error?: string }> {
	const { data, error } = await supabase
		.from("job_flow_templates")
		.select()
		.eq("id", templateId)
		.maybeSingle();
	return {
		data: data ? (data as JobFlowTemplate) : undefined,
		error: error?.message,
	};
}
