import translationCommonDe from "@assets/locales/de/common.json";
import translationListingDe from "@assets/locales/de/listing.json";
import translationOrderDe from "@assets/locales/de/order.json";
import translationUserDe from "@assets/locales/de/user.json";
import translationWorkerDe from "@assets/locales/de/worker.json";
import translationCommonEn from "@assets/locales/en/common.json";
import translationListingEn from "@assets/locales/en/listing.json";
import translationOrderEn from "@assets/locales/en/order.json";
import translationUserEn from "@assets/locales/en/user.json";
import translationWorkerEn from "@assets/locales/en/worker.json";
import translationGenerateEn from "@assets/locales/en/generate.json";
import translationGenerateDe from "@assets/locales/de/generate.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const languages = [
	{ key: "en", label: "English" },
	{ key: "de", label: "Deutsch" },
];

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		debug: false,
		resources: {
			en: {
				common: translationCommonEn,
				listing: translationListingEn,
				order: translationOrderEn,
				worker: translationWorkerEn,
				user: translationUserEn,
				generate: translationGenerateEn,
			},
			de: {
				common: translationCommonDe,
				listing: translationListingDe,
				order: translationOrderDe,
				worker: translationWorkerDe,
				user: translationUserDe,
				generate: translationGenerateDe,
			},
		},
		fallbackNS: "common",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
