import { create } from "zustand";
import {persist} from "zustand/middleware";
interface UserState {
	email?: string;
	setEmail: (email: string) => void;
}

export const useCreateEmailStore = create<UserState>()(persist((set) => ({
	setEmail: (email) => set(() => ({ email: email })),
}), {name: "email"}));
