import {useCreateStoryStore} from "@store/story.store";

import {RadioGroup, RadioGroupItem} from "@ui/radio-group";

import SlideWrapper from "@components/generate/components/slide-wrapper";
import { useTranslation } from "react-i18next";

const AgeForm = ({characterId}: {characterId: string}) => {
  const { t } = useTranslation("generate");
  const {data, editCharacterForm} = useCreateStoryStore((state) => state);
  const handleSelectAge = (e: React.MouseEvent<HTMLInputElement>)=>{
    const target = e.target as HTMLInputElement; 
    const value = target?.dataset.value
    if(value){
      editCharacterForm(characterId, 'age', 'value', value);
    }
  }
  return (
    <SlideWrapper className="mt-6">
      <div className="cards flex items-center justify-center">
        <div className="flex justify-center flex-col space-y-5">
          <h1 className="text-center font-bold text-lg">{t("select_age")}</h1>
        </div>
      </div>
      <div className="flex items-center my-3 w-full">
        <RadioGroup
          onValueChange={(e) =>editCharacterForm(characterId, 'age', 'value', e)}
          onClick={handleSelectAge}
          defaultValue={data.characters?.[characterId]?.formStages?.find(form=> form.name === 'age')?.value as string || ''}
          value={data.characters?.[characterId]?.formStages?.find(form=> form.name === 'age')?.value as string  || ''}
          className="flex flex-col space-y-1 w-full"
        >
          <div className="flex items-center space-x-3 space-y-0 p-4 rounded-md shadow cursor-pointer w-full" data-value="0-2">
            <RadioGroupItem value="0-2"/>
            <span>0-2</span>
          </div>
          <div className="flex items-center space-x-3 space-y-0 p-4 rounded-md shadow cursor-pointer" data-value="3-6">
            <RadioGroupItem value="3-6" />
            <span>3-6</span>
          </div>
          <div className="flex items-center space-x-3 space-y-0 p-4 rounded-md shadow cursor-pointer" data-value="7-12">
            <RadioGroupItem value="7-12" />
            <span>7-12</span>
          </div>
          <div className="flex items-center space-x-3 space-y-0 p-4 rounded-md shadow cursor-pointer" data-value="13+">
            <RadioGroupItem value="13+" />
            <span>13+</span>
          </div>
        </RadioGroup>
      </div>
    </SlideWrapper>
  );
};

export default AgeForm;
