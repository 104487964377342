import * as CatalogSerivce from "@services/catalog.service";
import { useQuery } from "@tanstack/react-query";

export function useGetCatalogItemVariationOfItem(itemId: string) {
	return useQuery({
		queryKey: ["catalog_item_variations_of_item", itemId],
		queryFn: async () => {
			const data = await CatalogSerivce.getCatalogItemVariationsOfItem(itemId)
			return data;
		},
	});
}
