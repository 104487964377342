import { cn } from "@lib/utils";
import {AnimatePresence, motion} from "framer-motion";

const FloatingFooter = ({
  className,
  children,
}: {
  className?: string; 
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: -110, opacity: 0}}
        animate={{ x: 0, opacity: 1}}
        exit={{ x: 1000, display: 'none'}}
        transition={{duration: 0.4}}
        className={cn("fixed z-50 bottom-0 left-0 backdrop-blur-sm bg-white/90 border-t w-full px-4 py-8 rounded-t-xl", className)}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default FloatingFooter;
