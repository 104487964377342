"use client";

import {Icons} from "@components/icons";
import {Input} from "@components/ui/input";
import {zodResolver} from "@hookform/resolvers/zod";
import {User} from "@models/user";
import * as Auth from "@services/auth.service";
import {useCreateUserStore} from "@store/auth.store";
import {useDialogStore} from "@store/dialog.store";
import {Button} from "@ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@ui/form";
import {useToast} from "@ui/use-toast";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import * as z from "zod";

export default function SignUp() {
  const [loginState, setLoginState] = useState({isLoading: false, error: ""});
  const location = useLocation();
  const {triggerDialog} = useDialogStore((state) => state);
  const locationStateRedirectUrl = location.state.redirectUrl;
  useEffect(() => {
    if (locationStateRedirectUrl) {
      triggerDialog(false);
    }
  }, []);
  const currentPath = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
  const {toast} = useToast();
  const {addUser} = useCreateUserStore((state) => state);
  const {t} = useTranslation("common");
  const formSchema = z.object({
    email: z.string().email(t("login.email_invalid") ?? ""),
    password: z.string().min(6, t("login.password_invalid") ?? ""),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit({email, password}: z.infer<typeof formSchema>) {
    setLoginState({...loginState, isLoading: true});
    const {error, data} = await Auth.signUp({
      email: email,
      password: password,
    });
    if (error) {
      if (error === "User already registered") {
        toast({
          variant: "destructive",
          description: t("login.error_account_exists") ?? error,
        });
        setLoginState({isLoading: false, error});
        return;
      }
      toast({
        variant: "destructive",
        description: t("login.error_failed_message") ?? error,
      });
      setLoginState({isLoading: false, error});
      return;
    }

    setLoginState({isLoading: false, error: ""});
    if (data) {
      addUser({
        fullName: data.user_metadata.full_name,
        email: data.email,
        avatarUrl: data.user_metadata.avatar_url,
        id: data.id,
      } as User);
	  if(location.pathname === "/sign-up"){
		return;
	  }
      window.location.href = locationStateRedirectUrl
        ? locationStateRedirectUrl
        : currentPath ?? "/";
    }
  }

  return (
    <div className="container relative h-screen flex items-center justify-center">
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
        <div className="space-y-4 text-center">
          <h1 className="text-2xl font-semibold tracking-tight">
            {t("login.create_account_header")}
          </h1>
          <p className="text-sm text-muted-foreground">
            {t("login.create_account_description")}
          </p>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({field}) => (
                <FormItem>
                  <FormLabel>{t("login.email")}</FormLabel>
                  <FormControl>
                    <Input placeholder="john.doe@doe.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({field}) => (
                <FormItem className="mt-0">
                  <FormLabel>{t("login.password")}</FormLabel>
                  <FormControl>
                    <Input placeholder="*******" type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="my-4 w-full"
              type="submit"
              disabled={loginState.isLoading}
            >
              {t("submit")}
              {loginState.isLoading && (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              )}
            </Button>
            <span className="text-xs flex justify-items-end">
              <Link to="/sign-in">{t("login.has_account_sign_in_here")}</Link>
            </span>
          </form>
        </Form>
        <p className="px-8 text-center text-sm text-muted-foreground">
          By clicking continue, you agree to our{" "}
          <a
            href="/terms"
            className="underline underline-offset-4 hover:text-primary"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="/privacy"
            className="underline underline-offset-4 hover:text-primary"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </div>
  );
}
