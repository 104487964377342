import {DE} from "@assets/privacy/de"
import {US} from "@assets/privacy/us"

export const getPrivacyByCountryCode = (countryCode: string|undefined|null = 'GB'): string => {
    switch (countryCode) {
      case 'de':
        return DE;
      default:
        return US;
    }
  }