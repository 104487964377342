import {Icons} from "@components/icons";
import { FORM_TYPE_KEY } from "@pages/generate";
import {Link, useLocation} from "react-router-dom";
import FibbiLogo from "@assets/images/fibbi-logo.webp"

const Header = ({
  formType,
}: {
  formType: string;
}) => {
  const {pathname} = useLocation();
  const link = {0: '/books', 1: `${pathname}?${FORM_TYPE_KEY}=0`, 2: `${pathname}?${FORM_TYPE_KEY}=1`, 3: `${pathname}?${FORM_TYPE_KEY}=2`, 4: `${pathname}?${FORM_TYPE_KEY}=3`}[
    formType
  ] as string;
  return (
    <div className="fixed top-0 flex border-b py-3 left-0 w-full backdrop-blur-sm bg-white/80 z-50 h-12">
      <Link to={link} className="absolute left-3 flex items-center text-sm">
        {formType !== "0" && <Icons.chevronLeft className="h-6 w-6" />}
        <span>
          {
            {
              0: "Back",
              1: "Back",
              2: "Back",
              3: "Back",
              4: "Back",
            }[formType]
          }
        </span>
      </Link>
    <div className="flex flex-1 justify-center items-center text-sm"> 
          <img src={FibbiLogo} className="h-20 w-20" />
        {/* {
          {
            0: "Overview",
            1: "",
            2: "Step 1 of 3",
            3: "Step 2 of 3",
            4: "",
          }[formType]
        } */}
      </div>
    </div>
  );
};

export default Header;
