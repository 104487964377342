import { Link } from "react-router-dom";
import "@src/styles/footer.css";
import { Icons } from "@components/icons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t} = useTranslation('common');
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="bg-primary text-primary-foreground mt-4 py-4">
    <div className="container">
        <ul className="flex space-x-4 my-4 justify-center">
          <li>
            <a href="mailto:support@fibbi.de" target="_blank">
              <Icons.mail className="w-6 h-6 hover:text-secondary" />
            </a>
          </li>
          <li>
            <Link to="https://twitter.com/FibbiMe" target="_blank">
              <Icons.twitter className="w-6 h-6 hover:text-secondary" />
            </Link>
          </li>
          <li>
          <Link to="https://www.linkedin.com/company/fibbime" target="_blank">
              <Icons.linkedin className="w-6 h-6 hover:text-secondary" />
            </Link>
          </li>
          <li>
          <Link to="https://instagram.com/fibbi_me" target="_blank">
              <Icons.instagram className="w-6 h-6 hover:text-secondary" />
            </Link>
          </li>
          <li>
          <Link to="https://www.facebook.com/people/Fibbi/61552355613518" target="_blank">
              <Icons.facebook className="w-6 h-6 hover:text-secondary" />
            </Link>
          </li>
        </ul>
      <div className="lines">
        <Link to="/">
        <p className="text-center text-primary-foreground text-2xl font-bold">Fibbi</p>
        </Link>
      </div>
      <div className="flex flex-col items-center justify-between sm:flex-row sm:items-baseline mt-8">
        <p className="mr-4 text-primary-foreground">&#169; {year} Fibbi. All rights reserved</p>
        <div className="flex">
          <Link to="/imprint" className="mr-4 underline underline-offset-4">
            {t("imprint")}
          </Link>
          <Link to="/privacy" className="mr-4 underline underline-offset-4">
            {t("privacy")}
          </Link>
          <Link to="/terms" className="underline underline-offset-4"> {t("terms")}</Link>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Footer;
