export const US = `<div class="space-y-10">

<div>
  <h1 class="font-bold mb-2">Data protection</h1>
  <h1 class="font-bold mb-2">General data protection regulations</h1>
  <p class="mb-2 text-sm">In the following we inform you about the collection of personal data when using our website. Personal data is all data that can be related to you personally, e.g. name, address, e-mail addresses, user behaviour. We have taken extensive technical and operational precautions to protect your data from accidental or intentional manipulation, loss, destruction or access by unauthorised persons. Our security procedures are regularly reviewed and adapted to technological progress.</p>
</div>

<div>
  <h1 class="font-bold mb-2">1 Person responsible for data processing</h1>
  <p class="mb-2 text-sm">The data controller pursuant to Art. 4 (7) of the EU General Data Protection Regulation (DSGVO) is Lazy 8 Labs UG (haftungsbeschränkt), service@fibbi.de, Stresemannstraße 25, 10963 Berlin (see our imprint).</p>
</div>

<div>
  <h1 class="font-bold mb-2">2 How to contact the data protection officer</h1>
  <p class="mb-2 text-sm">You can reach our data protection officer at service@fibbi.de or our postal address with the addition of "the data protection officer".</p>
</div>

<div>
  <h1 class="font-bold mb-2">3 Your rights
  </h1>
  <p class="mb-2 text-sm">You have the following rights in relation to us in respect of personal data relating to you:
  </p>
</div>

<div>
  <h1 class="font-bold mb-2">3.1 General rights</h1>
  <p class="mb-2 text-sm">You have the right to information, correction, deletion, restriction of processing, objection to processing and data portability. If processing is based on your consent, you have the right to revoke this with effect for the future.</p>
</div>

<div>
  <h1 class="font-bold mb-2">3.2 Rights in the case of data processing according to legitimate interest</h1>
  <p class="mb-2 text-sm">Pursuant to Article 21(1) DSGVO, you have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data relating to you which is carried out on the basis of Article 6(1)(f) DSGVO (processing of data for the purposes of legitimate interest); this also applies to profiling based on this provision. In the event of your objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims.</p>
</div>

<div>
  <h1 class="font-bold mb-2">3.3 Rights in case of direct advertising</h1>
  <p class="mb-2 text-sm">If we process your personal data for the purpose of direct marketing, you have the right to object at any time to the processing of personal data relating to you for the purpose of such marketing in accordance with Article 21(2) of the Data Protection Regulation; this also applies to profiling insofar as it is associated with such direct marketing.</p>
  <p class="mb-2 text-sm">In the event of your objection to processing for the purpose of direct marketing, we will no longer process your personal data for these purposes.</p>
</div>

<div>
  <h1 class="font-bold mb-2">3.4 Right to complain to a supervisory authority</h1>
  <p class="mb-2 text-sm">You also have the right to complain to a competent data protection supervisory authority about our processing of your personal data.
  </p>
</div>

<div>
  <h1 class="font-bold mb-2">4 Collection of personal data when visiting our website</h1>
  <p class="mb-2 text-sm">IP address, date and time of the request, time zone difference to Greenwich Mean Time (GMT), content of the request (specific page), access status/HTTP status code, amount of data transferred in each case, website from which the request comes, browser, operating system and its interface, language and version of the browser software.</p>
</div>

<div>
  <h1 class="font-bold mb-2">5 Use of the product configuration options and purchase</h1>
  <p class="mb-2 text-sm">On our website, we offer you the possibility to personalise products (e.g. colouring books, books) via our software by uploading a picture via the web application. In the course of this personalisation, depending on the product, the following data may be collected, among others: Photo of the user or of relatives persons and information such as age group or gender. We only use this data to create the personalised products you request. If you purchase one of your personalised products through our website, your data will be stored by us in accordance with the legal retention periods. If you do not purchase the products you have personalised via our website within 30 days, the data you have entered for this purpose will be deleted by us. The legal basis for the processing of this data is Art. 6 para. 1 b DSGVO.</p>
</div>

<div>
  <h1 class="font-bold mb-2">6 Contacting us by e-mail or contact form</h1>
  <p class="mb-2 text-sm">When you contact us by e-mail or via a contact form, the data you provide (your e-mail address, name and telephone number, if applicable) will be stored by us in order to answer your questions. The legal basis in this respect is Art. 6 para. 1 f DSGVO. Insofar as we request information via our contact form that is not required for contacting us, we have always marked this as optional. This information is used to specify your request and to improve the processing of your request. Any communication of this information is expressly on a voluntary basis and with your consent, Art. 6 para. 1 a DSGVO. Insofar as this involves information on communication channels (e.g. e-mail address, telephone number), you also consent to us contacting you via this communication channel, if necessary, in order to respond to your request. You can, of course, revoke this consent at any time for the future.
  We delete the data accrued in this context after the storage is no longer necessary or restrict the processing if there are legal retention obligations.</p>
</div>

<div>
  <h1 class="font-bold mb-2">8 Newsletter
  </h1>
</div>

<div>
  <h1 class="font-bold mb-2">8.1 General information
  </h1>
  <p class="mb-2 text-sm">With your consent in accordance with Art. 6 Para. 1 a DSGVO, you can subscribe to our newsletter, with which we inform you about our current offers. We use the so-called double opt-in procedure to register for our newsletter. This means that after your registration, we will send you an e-mail to the e-mail address you provided, in which we ask you to confirm that you wish to receive the newsletter.</p>
  <p class="mb-2 text-sm">In addition, we save the time you used to register and confirm. The purpose of this procedure is to be able to prove your registration and, if necessary, to clarify any possible misuse of your personal data.</p>
  <p class="mb-2 text-sm">The only mandatory data for sending the newsletter is your e-mail address. After your confirmation, we store your e-mail address for the purpose of sending you the newsletter. The legal basis is Art. 6 para. 1 a DSGVO.</p>
  <p class="mb-2 text-sm">You can revoke your consent to the sending of the newsletter at any time and unsubscribe from the newsletter. You can declare the revocation by clicking on the link provided in every newsletter email or by contacting the data protection officer stated above.</p>
</div>

<div>
  <h1 class="font-bold mb-2">8.2 Newsletter tracking
  </h1>
  <p class="mb-2 text-sm">We would like to point out that we evaluate your user behaviour when sending the newsletter. For this evaluation, the e-mails sent contain so-called web beacons or tracking pixels that are stored on our website. For the analyses, we link the above-mentioned data and the web beacons with your e-mail address and an individual ID. Links received in the newsletter also contain this ID.
  </p>
  <p class="mb-2 text-sm">With the data obtained in this way, we create a user profile in order to tailor the newsletter to your individual interests. In doing so, we record when you read our newsletters, which links you click on in them and deduce your personal interests from this. The information is stored for as long as you are subscribed to the newsletter. You can object to the tracking at any time by unsubscribing from our newsletter by clicking on the unsubscribe link provided in every email.</p>
  <p class="mb-2 text-sm">Such tracking is also not possible if you have deactivated the display of images by default in your e-mail programme. In this case, the newsletter will not be displayed to you in full and you may not be able to use all the functions. If you display the images manually, the above-mentioned tracking takes place.</p>
</div>

<div>
  <h1 class="font-bold mb-2">9 Registration and portal use</h1>
  <p class="mb-4 text-sm">You have the option of registering with us and creating a customer account. For the registration we collect and store the following data from you:</p>
  <p class="mb-2 text-sm">- Salutation</p>
  <p class="mb-2 text-sm">- First name</p>
  <p class="mb-2 text-sm">- Surname</p>
  <p class="mb-2 text-sm">- E-mail (user name)</p>
  <p class="mb-2 text-sm">- Your password</p>
  <p class="mb-4 text-sm">- Address details</p>
  <p class="mb-2 text-sm">The provision of the aforementioned data is mandatory; you can provide all other information voluntarily by using our portal.</p>
  <p class="mb-2 text-sm">If you use our portal, we will store your data required for the fulfilment of the contract, if applicable also information on the method of payment, until you finally delete your access. Furthermore, we store the voluntary data you provide for the duration of your use of the portal, unless you delete it beforehand. You can manage and change all details in the protected customer area. The legal basis is Art. 6 para. 1 a, b and f DSGVO.</p>
</div>

<div>
  <h1 class="font-bold mb-2">10 Online orders - Shop</h1>
  <p class="mb-2 text-sm">When you place an online order on our website, we collect various data required for the conclusion of the contract. The legal basis is the conclusion and execution of a contract according to Art. 6 para. 1 b DSGVO. The data is stored for the duration of the contract and in accordance with legal obligations. For payment processing, we use various payment service providers, which are always identified and directly accept your entries and are thus recipients of your personal data collected in connection with the payment process. The legal basis for the involvement of payment service providers is the processing of the contract in accordance with Art. 6 Para. 1 b DSGVO. Storage for the purpose of payment is for the duration of the payment transaction.</p>
</div>

<div>
  <h1 class="font-bold mb-2">11 Participation in competitions
  </h1>
  <p class="mb-2 text-sm">If you participate in competitions, we collect data that is necessary for the implementation of the competition. This is usually an individual competition entry (e.g. a comment or a photo), as well as name and contact details. We may pass on your data to our competition partners, e.g. in order to send you the prize. The data processing and data transfer may vary depending on the competition and is therefore specifically described in the respective conditions of participation. Participation in the competition and the associated data collection is of course voluntary. Legal basis for data processing.</p>
</div>

<div>
  <h1 class="font-bold mb-2">12 Applications</h1>
  <p class="mb-2 text-sm">You can apply to our company electronically, in particular by e-mail. We will of course only use your details to process your application and will not pass them on to third parties. Please note that e-mails sent unencrypted will not be protected against access.
  </p>
  <p class="mb-2 text-sm">If you have applied for a specific position and it has already been filled or if we consider you to be equally or even more suitable for another position, we would be happy to forward your application within our company. Please let us know if you do not agree to this. Your application will only be forwarded to other companies within our group of companies if you give us your consent to do so.</p>
  <p class="mb-2 text-sm">Your personal data will be deleted immediately after completion of the application process, or after a maximum of 6 months, unless you have given us your express consent to store your data for longer or a contract has been concluded. The legal basis is Art. 6 Para. 1 a, b and f DSGVO and § 26 BDSG.</p>
</div>

<div>
  <h1 class="font-bold mb-2">13 Cookies</h1>
  <p class="mb-2 text-sm">Cookies are data that are stored on your computer by a website you visit and enable your browser to be reassigned. Cookies transmit information to the entity that sets the cookie. Cookies can store various information, such as your language setting, the duration of your visit to our website or the entries you have made there. This prevents you, for example, from having to re-enter required form data each time you use the site. The information stored in cookies can also be used to recognise preferences and to tailor content according to areas of interest. There are different types of cookies: session cookies are sets of data that are only temporarily held in memory and deleted when you close your browser. Permanent or persistent cookies are automatically deleted after a set period of time, which may vary depending on the cookie. With this type of cookie, the information can also be stored in text files on your computer. However, you can also delete these cookies at any time via your browser settings. First-party cookies are set by the website you are currently visiting. Only this website is allowed to read information from these cookies. Third-party cookies are set by organisations that do not operate the website you are visiting. These cookies are used by marketing companies, for example. The legal basis for possible processing of personal data by means of cookies and their storage period may vary. Insofar as you have given us your consent, the legal basis is Art.6 para.1 p.1 lit.a DSGVO. Insofar as the data processing is based on our overriding legitimate interests, the legal basis is Art.6 para.1 p.1 lit.f DSGVO. The stated purpose then corresponds to our legitimate interest. We use cookies to ensure the proper operation of the website, to provide basic functionalities, for range measurement and to tailor our services to preferred areas of interest.</p>
  <p class="mb-2 text-sm">You can delete cookies already stored on your end device at any time. If you want to prevent cookies from being saved, you can do this via the settings in your internet browser. You can find instructions for common browsers here: Microsoft Internet Explorer, Mozilla Firefox, Google Chrome, Google Chrome mobile, Microsoft Edge Browser, Safari, Safari mobile. Alternatively, you can also install so-called ad blockers. Please note that individual functions of our website may not work if you have deactivated the use of cookies.</p>
  <p class="mb-2 text-sm">When accessing our website, all users of our website are also informed by an information banner about our use of cookies and referred to this data protection notice. As a user, you will also be asked for your consent to the use of certain cookies, particularly those relevant to the personalisation of services and marketing measures. Once you have given your consent, you can revoke it at any time with effect for the future by calling up the cookie settings via the accompanying data protection icon at the edge of the screen and changing your selection there.</p>
</div>

<div>
  <h1 class="font-bold mb-2">14 Google Analytics
  </h1>
  <p class="mb-2 text-sm">If you have given your consent, this website uses Google Analytics, a web analytics service provided by Google LLC. The responsible entity for users in the EU/EEA and Switzerland is Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland ("Google"). The use includes the Universal Analytics mode of operation. This makes it possible to assign data, sessions and interactions across multiple devices to a pseudonymous user ID and thus to analyse a user's activities across devices. Google Analytics uses cookies that enable an analysis of your use of our websites. The information collected by means of the cookies about your use of this website is generally transferred to a Google server in the USA and stored there.</p>
  <p class="mb-4 text-sm">We use the function 'anonymizeIP' (so-called IP masking): Due to the activation of IP anonymisation on this website, your IP address will be shortened by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data. During your visit to the website, the following data is collected, among other things:</p>
  <p class="mb-2 text-sm">- The pages you visit, your "click path".
  </p>
  <p class="mb-2 text-sm">- Achievement of "website goals" (conversions, e.g. newsletter sign-ups, downloads, purchases)
  </p>
  <p class="mb-2 text-sm">- Your user behaviour (e.g. clicks, dwell time, bounce rates)
  </p>
  <p class="mb-2 text-sm">- Your approximate location (region)</p>
  <p class="mb-2 text-sm">- Your IP address (in shortened form)</p>
  <p class="mb-2 text-sm">- Technical information about your browser and the end devices you use (e.g. language setting, screen resolution)</p>
  <p class="mb-2 text-sm">- your internet service provider</p>
  <p class="mb-2 text-sm">- the referrer URL (via which website/advertising medium you came to this website)</p>
  <p class="mb-2 text-sm">On behalf of the operator of this website, Google will use this information for the purpose of evaluating your anonymous use of the website and compiling reports on website activity. The reports provided by Google Analytics are used to analyse the performance of our website and the success of our marketing campaigns.</p>
  <p class="mb-2 text-sm">The recipient of the data is Google Ireland Limited as an order processor. We have concluded an order processing agreement with Google for this purpose. Google LLC, based in California, USA, and possibly US authorities can access the data stored by Google. A transfer of data to the USA cannot be ruled out.</p>
  <p class="mb-2 text-sm">The data sent by us and linked to cookies are automatically deleted after 14 months. The deletion of data whose retention period has been reached takes place automatically once a month. You can also prevent the storage of cookies by configuring your browser software accordingly. However, if you configure your browser to reject all cookies, functionalities on this and other websites may be restricted. Furthermore, you can prevent the collection of the data generated by the cookie and related to your use of the website (incl. your IP address) to Google as well as the processing of this data by Google by a. not giving your consent to the setting of the cookie or b. downloading and installing the browser add-on to deactivate Google Analytics.</p>
  <p class="mb-2 text-sm">
  The legal basis for this data processing is your consent in accordance with Art. 6 para. 1 sentence 1 lit. a DSGVO. You can revoke your consent at any time with effect for the future by calling up the cookie administration via the accompanying data protection icon at the edge of the screen and removing the tick behind the processing to which you had consented. For more information on the terms of use of Google Analytics and data protection at Google, please visit <a href="https://marketingplatform.google.com/about/analytics/terms/de"> https://marketingplatform.google.com/about/analytics/terms/de/</a> and <a href="https://policies.google.com/?hl=de">https://policies.google.com/?hl=de</a></p>
</div>

<div>
  <h1 class="font-bold mb-2">15 Klaviyo</h1>
  <p class="mb-2 text-sm">We use the Klaviyo service for our email marketing activities. Klaviyo is a service that enables the organisation and analysis of email campaigns. When you register for our newsletter, the data you enter (e.g. email address) is transferred to Klaviyo and stored there.</p>
  <p class="mb-2 text-sm">Functions of Klaviyo:</p>
  <p class="mb-2 text-sm">Sending newsletters</p>
  <p class="mb-2 text-sm">Organisation and analysis of e-mail campaigns</p>
  <p class="mb-2 text-sm">Storage of e-mail addresses and other data provided during newsletter registration
  The data entered as part of the newsletter subscription is stored exclusively on Klaviyo's servers and is not passed on to third parties. Klaviyo uses this information to send and evaluate the newsletter on our behalf.</p>
  <p class="mb-2 text-sm">For further information on data protection at Klaviyo, please refer to Klaviyo's data protection policy: [https://www.klaviyo.com/legal/privacy/privacy-notice]</p>
</div>

<div>
  <h1 class="font-bold mb-2">15 Online presences on Facebook, Instagram, TikTok, LinkedIn, Twitter, YouTube and Pinterest</h1>
  <p class="mb-2 text-sm">We maintain online presences on the aforementioned social networks and platforms in order to be able to communicate with the customers, interested parties and users who are registered and active there and to inform them about our offers and services.</p>
  <p class="mb-2 text-sm">User data is usually processed by the social networks and platforms for market research and advertising purposes. In this way, usage profiles can be created by the providers from your usage behaviour and the resulting interests. The usage profiles can then be used to place suitable advertisements within and outside the platforms that correspond to your presumed interests. For these purposes, cookies are usually stored on the users' computers, in which the usage behaviour and interests are stored. Furthermore, data may also be stored in the usage profiles irrespective of the devices used (especially if you as a user are a member of the respective platforms and are logged in to them). You can delete cookies at any time via the browser settings.
  The processing of personal data is based on our legitimate interests in effective information and timely communication with users pursuant to Art. 6 para. 1 lit. f) DSGVO. If users are asked by the respective providers for consent to data processing (i.e. you declare your consent e.g. when logging in/registering by ticking a checkbox or confirming a button), the legal basis of the processing is Art. 6 para. 1 lit. a.), Art. 7 DSGVO.</p>
  <p class="mb-2 text-sm">When using social networks and platforms, your data may be processed outside the European Union (EU) or the European Economic Area (EEA). In these countries, there is generally no comparable data protection standard to the EU/EEA. This may result in risks, especially because it may be more difficult to enforce your rights (e.g. to information, correction or deletion). For a detailed description of the respective processing and the options to object (opt-out) if you do not agree to the data processing, please refer to the information of the providers of the social networks and platforms used by us linked below. In the event of requests for information and the assertion of your user rights, these can also be asserted most effectively with the providers. Only the providers have access to the user data and can directly take appropriate measures and provide information. If you still need help, please feel free to contact us at</p>
  <ul class="space-y-2>
    <li>Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland) - Privacy Policy, Opt-Out and http://www.youronlinechoices.com/, Privacy Shieldt</li>
    <li>Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) – Datenschutzerklärung, Opt-Out, Privacy Shield</li>
    <li> Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) – Datenschutzerklärung/ Opt-Out</li>
    <li>https://www.tiktok.com/legal/page/eea/privacy-policy/de</li>
    <li>https://de.linkedin.com/legal/privacy-policy</li>
    <li>https://twitter.com/de/privacy</li>
  </ul>
</div>

<div>
  <h1 class="font-bold mb-2">18 Data transmission</h1>
  <p class="mb-2 text-sm">As a matter of principle, your data will not be transferred to third parties unless we are legally obliged to do so, or the transfer of data is necessary for the implementation of the contractual relationship, or you have previously expressly consented to the transfer of your data.</p>
  <p class="mb-2 text-sm">External service providers and partner companies, such as online payment providers or the shipping company commissioned with the delivery, only receive your data insofar as this is necessary to process your order. In these cases, however, the scope of the transmitted data is limited to the necessary minimum. Insofar as our service providers come into contact with your personal data, we ensure within the framework of order processing pursuant to Art. 28 DSGVO that they comply with the provisions of the data protection laws in the same manner. Please also note the respective data protection notices of the providers. The respective service provider is responsible for the content of third-party services, whereby we check the services for compliance with the legal requirements within the scope of reasonableness.</p>
  <p class="mb-2 text-sm">We make a point of processing your data within the EU / EEA. However, we may use service providers who process data outside the EU / EEA. In these cases, we ensure that an adequate level of data protection is established at the recipient before transferring your personal data. This means that via EU standard data protection clauses or an adequacy decision, a level of data protection is achieved that is comparable to the standards within the EU.</p>
</div>

<div>
  <h1 class="font-bold mb-2">19 Data security
  </h1>
  <p class="mb-2 text-sm">We have taken extensive technical and operational precautions to protect your data from accidental or intentional manipulation, loss, destruction or access by unauthorised persons. Our security procedures are regularly reviewed and adapted to technological progress.</p>
</div>
<div>
  <p class="mb-2 text-sm">October 2023
  </p>
</div>


</div>
`;
