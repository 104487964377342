import {Order} from "@models/order";
import * as OrderAccess from "@access/order.access";
import {EventEnum, SubscriptionStatusTable} from "@typesClient/common";
import {FulfillmentType} from "@models/order-fulfillment";

export async function placeOrder(
  order: Omit<Order, "id" | "createdAt" | "updatedAt">
): Promise<{error?: string; data?: Order}> {
  return OrderAccess.placeOrder(order);
}

export async function getOrder(
  id: string
): Promise<{data?: Order; error?: string}> {
  return await OrderAccess.getOrder(id);
}

export async function updateOrderFulfillmentType({
  orderId,
  fulfillmentType,
}: {
  orderId: string;
  fulfillmentType: FulfillmentType;
}): Promise<{data?: Order; error?: string}> {
  return await OrderAccess.updateOrderFulfillmentType({
    orderId,
    fulfillmentType,
  });
}

export interface SubscribeToChangesProps {
  onChanges?: (event: {
    eventType: EventEnum;
    new: Order | undefined;
    old: Partial<Order>;
  }) => void;
  onSubscriptionStatusChanges?: (status: SubscriptionStatusTable) => void;
}

export function subscribeToChanges(props: SubscribeToChangesProps): {
  unsubscribe: () => void;
} {
  return OrderAccess.subscribeToChanges(props);
}
