import SectionHeader from "@components/section-header";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ui/accordion";
import {useTranslation} from "react-i18next";

const Faq: React.FC = () => {
  const {t} = useTranslation("common");
  return (
    <div id="faq" className="pt-16 mb-10 container">
      <div className="grid md:grid-cols-2 grid-cols-1">
        <div className="mb-10">
      <SectionHeader
        title={t("faq.title")}
        className="text-left text-4xl"
      />
        <span className="text-muted-foreground">
         {t("have_more_question")} <a className="text-primary" href="mailto:support@fibbi.de">support@fibbi.de</a>  
        </span>
        </div>
      <Accordion type="single" collapsible className="">
        <AccordionItem value="item-1">
          <AccordionTrigger className="text-left mt-0 pt-0">
            {t("faq.q1")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a1")}</AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
          <AccordionTrigger className="text-left">
            {t("faq.q2")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a2")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3">
          <AccordionTrigger className="text-left">
            {t("faq.q3")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a3")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-4">
          <AccordionTrigger className="text-left">
            {t("faq.q4")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a4")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-5">
          <AccordionTrigger className="text-left">
            {t("faq.q5")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a5")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-6">
          <AccordionTrigger className="text-left">
            {t("faq.q6")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a6")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-7">
          <AccordionTrigger className="text-left">
            {t("faq.q7")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a7")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-8">
          <AccordionTrigger className="text-left">
            {t("faq.q8")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a8")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-9">
          <AccordionTrigger className="text-left">
            {t("faq.q9")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a9")}</AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-10">
          <AccordionTrigger className="text-left">
            {t("faq.q10")}
          </AccordionTrigger>
          <AccordionContent>{t("faq.a10")}</AccordionContent>
        </AccordionItem>
      </Accordion>
      </div>
    </div>
  );
};

export default Faq;
