import { Money } from "@models/money.ts";
import { OrderFulfillment } from "@models/order-fulfillment";
import { OrderLineItem } from "@models/order-line-item";
import { Tender } from "@models/tender";

export enum OrderState {
  /** Indicates that the order is in a draft state. Draft orders can be updated, but cannot be paid or fulfilled. */
	DRAFT = "DRAFT",
  /** Indicates that the order is open. Open orders can be updated. */
	OPEN = "OPEN",
  /** Indicates that the order is completed. Completed orders are fully paid. This is a terminal state. */
	COMPLETED = "COMPLETED",
  /** Indicates that the order is canceled. Canceled orders are not paid. This is a terminal state. */
	CANCELED = "CANCELED",
}

/**
 * Contains all information related to a single order to process with platform, including line items that specify the products to purchase.
 */
export interface Order {
  /** The unique ID of this object. */
  id: string;
  /** The ID of the user associated with the order. */
  userId: string;

  /** User locales, in IETF BCP 47 format, which based on ISO 639 and ISO 3166-1, eg. de-DE, en-US */
  userLocales?: string[];

  /** The line items included in the order. */
  lineItems: OrderLineItem[];
  /**
   * Details on order fulfillment.
   *
   * Orders can only be created with at most one fulfillment. However, orders returned by the API may contain multiple fulfillments.
   */
  fulfillments: OrderFulfillment[];
  /** The Tenders which were used to pay for the Order. */
  tenders?: Tender[];

  /** The current state of the order. */
  state: OrderState;

  /** The subtotal amount of money to collect for the order. */
  subtotalMoney: Money;
  /** The total discount amount of money to collect for the order. */
  totalDiscountMoney?: Money;
  /** The total tax amount of money to collect for the order. */
  totalTaxMoney?: Money;
  /** The total tip amount of money to collect for the order. */
  totalTipMoney?: Money;
  /** The total amount of money to collect for the order. */
  totalMoney: Money;

  /**
   * Application-defined data attached to this order. Metadata fields are intended to store descriptive references
   * or associations with an entity in another system or store brief information about the object.
   * Platform does not process this field; it only stores and returns it in relevant API calls.
   * Do not use metadata to store any sensitive information (personally identifiable information, card details, etc.).
   *
   * Keys written by applications must be 60 characters or less and must be in the character set [a-zA-Z0-9_-].
   * Entries may also include metadata generated by the platform. These keys are prefixed with a namespace,
   * separated from the key with a ':' character.
   *
   * Values have a max length of 255 characters.
   *
   * An application may have up to 10 entries per metadata field.
   *
   * Entries written by applications are private and can only be read or modified by the same application.
   */
  metadata?: { [key: string]: string };

  /** The time when the object was created, in RFC 3339 format. */
  createdAt: string;
  /** The time when the object was created, in RFC 3339 format. */
  updatedAt: string;
  /** The timestamp indicating when the order was placed, in RFC 3339 format. */
  placedAt?: string;
  /** The timestamp for when the order reached a terminal state, in RFC 3339 format (for example "2016-09-04T23:59:33.123Z"). */
  closedAt?: string;
}
