import { CustomCharacter } from "@models/custom-character";
import {CharacterUI, FormStage, FormValue} from "@store/story.store";


export const mapToCharacterUI = (character: CustomCharacter): CharacterUI => {
  return {
    character,
    image: undefined, //character.catalog_images[0]?.url,
    completed: false,
    formStatusQuo: character.toset ? convertToUIFormStatusQuo(character.toset) : null,
    formStages: character.toset ? convertToUIForm(character.toset) : null,
    currentNewActiveImage: null,
  };
};

const convertToUIForm = (toset: string): FormStage[] => {
  // make form: files as the last element
  const formStatusArray = convertToUIFormStatusQuo(toset);
  const formDefaultValueMapper = formStatusArray.map((form) => {
    return {name: form as FormValue, status: false, value: null};
  });
  return formDefaultValueMapper
};

const convertToUIFormStatusQuo = (toset: string): FormValue[] => {
  const formStatusArray = toset.split(",") as FormValue[];
  const filesIndex = formStatusArray.indexOf("files");
  if (filesIndex !== -1) {
    formStatusArray.splice(filesIndex, 1); // Remove 'files' from its current position
    formStatusArray.push("files"); // Add 'files' to the last position
  }
  return formStatusArray;
};
