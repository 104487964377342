import { useTranslation } from "react-i18next";

const Analitics = () => {
    const { t } = useTranslation("common");
    return ( 
        <div className="py-10 my-10 bg-muted">
            <div className="container">
                <div className="flex justify-center gap-24 flex-col md:flex-row items-center">
                    <div className="flex flex-col justify-center items-center space-y-1">
                        <div className="text-3xl font-bold">90k+</div>
                        <div className="text-muted-foreground text-xs uppercase text-center">{t("fibbi_users")}</div>
                    </div>
                    <div className="flex flex-col justify-center items-center space-y-1">
                        <div className="text-3xl font-bold">270k+</div>
                        <div className="text-muted-foreground text-xs uppercase text-center">{t("coloring_pages_an")}</div>
                    </div>
                    <div className="flex flex-col justify-center items-center space-y-1">
                        <div className="text-3xl font-bold">1m+</div>
                        <div className="text-muted-foreground text-xs uppercase text-center">{t("happy_coloring_hours")}</div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Analitics;