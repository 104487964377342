import i18n from "@config/i18n";
import {useGetCatalogItemsQuery} from "@hooks/useGetCatalogItemsQuery";
import {useScrollToTop} from "@hooks/useScrollToTop";
import {Button} from "@ui/button";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Books = () => {
  const {t} = useTranslation(["common", "generate"]);
  useScrollToTop();
  const {data: catalogItemsResult} = useGetCatalogItemsQuery({language: i18n.language});
  return (
    <div className="container mt-4 mb-20 flex items-center justify-center flex-col">
      <div className="text-2xl font-bold mb-5">{t("all_books")}</div>
      <div className="flex gap-8 flex-wrap justify-center ">
        {catalogItemsResult?.data &&
          catalogItemsResult?.data.map((book, index) => {
            return (
              <Link
                key={index}
                to={`/generate/${book.id}`}
                className="w-full md:w-auto"
              >
                <div
                  className="book-container w-full md:w-60 object-center border-2 rounded-lg"
                  key={index}
                >
                  <img
                    className="h-72 w-full object-cover rounded-t-lg object-top"
                    src={book.catalog_images?.[0]?.url}
                    alt="Image title"
                  />
                  <div className="relative border-t p-2 rounded-b-lg h-36 flex flex-col justify-between">
                    <div>
                      <div className="text-sm font-bold line-clamp-1">
                        {book.name}
                      </div>
                      <div className="text-xs text-foreground/50 mt-1 line-clamp-3">
                        {book.descriptionPlaintext}
                      </div>
                    </div>
                    <Button className="w-full">
                      {t("personalize", {ns: "generate"})}
                    </Button>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
      <Link to={"/"}>
        <Button className="mt-10 mx-auto w-full sm:w-72 flex">
          {t("back_to_home")}
        </Button>
      </Link>
    </div>
  );
};

export default Books;
