import { Address } from "@models/address.ts";
import { OrderFulfillmentEntry } from "@models/order-fulfillment-entry.ts";

export enum FulfillmentType {
  /** A recipient to pick up the fulfillment from a physical location. */
	PICKUP = "PICKUP",
  /** A shipping carrier to ship the fulfillment. */
	SHIPMENT = "SHIPMENT",
  /** A digital form of the fulfillment. */
	DIGITAL = "DIGITAL",
}

export enum FulfillmentState {
  /** Indicates that the fulfillment has been proposed. */
	PROPOSED = "PROPOSED",
  /** Indicates that the fulfillment has been reserved. */
	RESERVED = "RESERVED",
  /** Indicates that the fulfillment has been prepared. */
	PREPARED = "PREPARED",
  /** Indicates that the fulfillment was successfully completed. */
	COMPLETED = "COMPLETED",
  /** Indicates that the fulfillment was canceled. */
	CANCELED = "CANCELED",
  /** Indicates that the fulfillment failed to be completed, but was not explicitly canceled. */
	FAILED = "FAILED",
}

/**
 * Contains details about how to fulfill this order.
 */
export interface OrderFulfillment {
  /** The type of the fulfillment. */
  type: FulfillmentType;
  /** The state of the fulfillment. */
  state: FulfillmentState;

  /**
   * A list of entries pertaining to the fulfillment of an order. Each entry must reference a valid
   * uid for an order line item in the line_item_uid field, as well as a quantity to fulfill.
   *
   * Multiple entries can reference the same line item uid, as long as the total quantity among
   * all fulfillment entries referencing a single line item does not exceed the quantity of the order's line item itself.
   *
   * An order cannot be marked as COMPLETED before all fulfillments are COMPLETED, CANCELED, or FAILED.
   * Fulfillments can be created and completed independently before order completion.
   */
  entries?: OrderFulfillmentEntry[];

  emailAddress?: string;

  /** Contains details for a pickup fulfillment. These details are required when the fulfillment type is PICKUP. */
  pickupDetails?: any;
  /** Contains details for a shipment fulfillment. These details are required when the fulfillment type is SHIPMENT. */
  shipmentDetails?: {
    address: Address;
  };
}
