import kid from "@assets/images/carousel/boy.webp";
import bear from "@assets/images/carousel/teddy.webp";
import doggo from "@assets/images/carousel/dog.webp";
import girl from "@assets/images/carousel/girl.webp";
import magic from "@assets/images/2-section.webp";
import bookResult from "@assets/video.mp4";
import gallery from "@assets/images/gallery.webp";
import step1 from "@assets/images/step/1.webp";
import step2 from "@assets/images/step/2.webp";
import step3 from "@assets/images/step/3.webp";
import step4 from "@assets/images/step/4.webp";
import {Icons} from "@components/icons";
import {Button} from "@ui/button";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Steps = () => {
  const {t} = useTranslation("common");
  return (
    <div id="how_it_works" className="pt-6 container">
      {/* Extra step  */}
      <div className="w-full flex justify-center ">
        <div className="w-16 h-16 rounded-full flex justify-center items-center relative">
          <div className="absolute inset-0 flex items-center [container-type:inline-size]">
            <div className="absolute h-[100cqw] w-[100cqw] animate-spin bg-[conic-gradient(from_0_at_50%_50%,rgba(255,131,189,0.5)_0deg,transparent_60deg,transparent_300deg,rgba(255,186,109,1)_360deg)] transition duration-300 [animation-duration:3s] opacity-100 rounded-full"></div>
          </div>

          <div className="absolute inset-0.5 rounded-full bg-primary"></div>
          <div className="absolute bottom-0 left-1/2 h-1/3 w-4/5 -translate-x-1/2 rounded-full bg-white/10blur-md transition-all duration-500  opacity-100"></div>
          <div className="relative z-20 text-background font-bold text-3xl">
            1
          </div>
          <span className="h-16 w-1 bg-gradient-to-b from-primary to-purple-100 absolute top-full"></span>
        </div>
      </div>
      <div className="mt-20 space-y-4 text-center">
        <h1 className="text-4xl font-bold">{t("steps_extra_title")}</h1>
        <p className="text-black/50">{t("steps_extra_desc")}</p>
      </div>
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 self-center justify-items-center gap-7 max-w-2xl m-auto">
        <Link to="/books">
        <img src={step1} className="h-80 bg-primary rounded-lg" />
        </Link>
        <Link to="/books">
        <img src={step2} className="h-80 bg-[#660df5] rounded-lg" />
        </Link>
        <Link to="/books">
        <img src={step3} className="h-80 bg-[#fc56c8] rounded-lg sm:block hidden" />
        </Link>
        <Link to="/books">
        <img src={step4} className="h-80 bg-[#fddc35] rounded-lg sm:block hidden" />
        </Link>
      </div>
      {/* Step 1 */}
      <div className="w-full flex justify-center mt-24">
        <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center relative">
          <span className="h-16 w-1 bg-gradient-to-b to-primary from-purple-100 absolute bottom-full"></span>
          <div className="absolute inset-0 flex items-center [container-type:inline-size]">
            <div className="absolute h-[100cqw] w-[100cqw] animate-spin bg-[conic-gradient(from_0_at_50%_50%,rgba(255,131,189,0.5)_0deg,transparent_60deg,transparent_300deg,rgba(255,186,109,1)_360deg)] transition duration-300 [animation-duration:3s] opacity-100 rounded-full"></div>
          </div>

          <div className="absolute inset-0.5 rounded-full bg-primary"></div>
          <div className="relative text-background font-bold text-3xl">2</div>
          <span className="h-16 w-1 bg-gradient-to-b from-primary to-purple-100 absolute top-full"></span>
        </div>
      </div>
      <div className="mt-20 space-y-4 text-center">
        <h1 className="text-4xl font-bold">{t("steps_title_1")}</h1>
        <p className="text-black/50">{t("steps_desc_1")}</p>
      </div>
      <div className="flex justify-center mt-4">
        <div className="space-y-4 flex flex-col items-center justify-center">
          <img
            src={doggo}
            alt="Image"
            className="mx-auto hover:scale-105 transition-transform border-2 rounded-md w-48 object-cover"
          />
          <img
            src={kid}
            alt="Image"
            className="mx-auto hover:scale-105 transition-transform border-2 rounded-md w-48 object-cover"
          />
        </div>
        <div className="border-8 rounded-[1.4rem] border-black sm:mx-4 mx-1 shadow-lg">
          <img
            src={gallery}
            className=" object-cover h-96 w-96 sm:w-44 rounded-[1.4rem]"
          />
        </div>
        <div className="space-y-4 flex flex-col items-center justify-center">
          <img
            src={girl}
            alt="Image"
            className="mx-auto hover:scale-105 transition-transform border-2 rounded-md w-48 object-cover"
          />
          <img
            src={bear}
            alt="Image"
            className="mx-auto hover:scale-105 transition-transform border-2 rounded-md w-48 object-cover"
          />
        </div>
      </div>
      {/* Step 2 */}
      <div className="w-full flex justify-center mt-24">
        <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center relative">
          <span className="h-16 w-1 bg-gradient-to-b to-primary from-purple-100 absolute bottom-full"></span>
          <div className="absolute inset-0 flex items-center [container-type:inline-size]">
            <div className="absolute h-[100cqw] w-[100cqw] animate-spin bg-[conic-gradient(from_0_at_50%_50%,rgba(255,131,189,0.5)_0deg,transparent_60deg,transparent_300deg,rgba(255,186,109,1)_360deg)] transition duration-300 [animation-duration:3s] opacity-100 rounded-full"></div>
          </div>

          <div className="absolute inset-0.5 rounded-full bg-primary"></div>
          <div className="relative text-background font-bold text-3xl">3</div>
          <span className="h-16 w-1 bg-gradient-to-b from-primary to-purple-100 absolute top-full"></span>
        </div>
      </div>
      <div className="mt-20 space-y-4 text-center">
        <h1 className="text-4xl font-bold ">{t("steps_title_2")}</h1>
        <p className="text-black/50">{t("steps_desc_2")}</p>
      </div>
      <div className="flex justify-center mt-4">
        <img src={magic} className="h-96" />
      </div>
      {/* Step 3 */}
      <div className="w-full flex justify-center mt-24">
        <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center relative">
          <span className="h-16 w-1 bg-gradient-to-b to-primary from-purple-100 absolute bottom-full"></span>
          <div className="absolute inset-0 flex items-center [container-type:inline-size]">
            <div className="absolute h-[100cqw] w-[100cqw] animate-spin bg-[conic-gradient(from_0_at_50%_50%,rgba(255,131,189,0.5)_0deg,transparent_60deg,transparent_300deg,rgba(255,186,109,1)_360deg)] transition duration-300 [animation-duration:3s] opacity-100 rounded-full"></div>
          </div>

          <div className="absolute inset-0.5 rounded-full bg-primary"></div>
          <div className="relative text-background font-bold text-3xl">4</div>
        </div>
      </div>
      <div className="mt-8 space-y-4 text-center">
        <h1 className="text-4xl font-bold ">{t("steps_title_3")}</h1>
        <p className="text-black/50">{t("steps_desc_3")}</p>
      </div>
      <div className="flex justify-center mt-4">
        <video muted autoPlay className="h-96 rounded-lg" loop playsInline>
          <source src={bookResult} />
        </video>
      </div>
      <Link to="/books">
        <Button className="mt-10 rounded-full mx-auto flex fancy-hover">
          <span>{t("start_magic")}</span>
          <Icons.chevronRight />
        </Button>
      </Link>
    </div>
  );
};

export default Steps;
