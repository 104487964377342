
import VerifyForm from "@components/login/verify.form";
import SignInForm from "@components/login/sign-in.form";
import { useCreateEmailStore } from "@store/email.store";

export default function Login() {
  const {email} = useCreateEmailStore()
  return (
    <>
      {email ? (
        <VerifyForm />
      ) : <SignInForm />}
    </>
  );
}
