import * as OrderService from "@services/order.service";
import { useQuery } from "@tanstack/react-query";
import {FulfillmentType} from "@models/order-fulfillment";

export function useGetOrderQuery({orderId, fullfillmentType, onError}:{orderId: string | null, fullfillmentType?: FulfillmentType | null, onError?: ()=> void}) {
	return useQuery({
		queryKey: ["order", orderId, fullfillmentType],
		enabled: orderId !== null,
		onError: () => {
			if(onError){
				onError();
			}
		},
		queryFn: async () => {
			const { data: order } = orderId
				? await OrderService.getOrder(orderId)
				: { data: undefined };
			return order;
		},
	});
}
