import axios from "axios";

import { Order } from "@models/order";
import { supabase } from "@config/supabaseClient";
import { toCamelCaseJson } from "@utils/string.utils";
import { EventEnum } from "@typesClient/common";
import { SubscribeToChangesProps } from "@services/order.service";
import { FulfillmentType } from "@models/order-fulfillment";

export async function placeOrder(
  order: Omit<Order, 'id' | 'createdAt' | 'updatedAt'>,
): Promise<{ data?: Order, error?: string }> {
  let result: Order | undefined;
  let errorMessage: string | undefined;

  try {
    const baseUrl = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;

    result = (await axios.post(baseUrl + '/order', order)).data.order as Order;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      errorMessage = error.message;
    } else {
      errorMessage = 'Failed to place order: ' + error;
    }
  }

  return {
    data: result,
    error: errorMessage,
  };
}
export async function updateOrderFulfillmentType(
{orderId, fulfillmentType}:{
	orderId: string;
	fulfillmentType: FulfillmentType;
}
): Promise<{ data?: Order, error?: string }> {
  let result: Order | undefined;
  let errorMessage: string | undefined;

  try {
    const baseUrl = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;

    result = (await axios.patch(baseUrl + '/order/fulfillmenttype', {orderId, fulfillmentType  })).data.order as Order;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      errorMessage = error.message;
    } else {
      errorMessage = 'Failed to update order fulfillment type: ' + error;
    }
  }
  return {
    data: result,
    error: errorMessage,
  };
}

export async function getOrder(
	orderId: string
): Promise<{ data?: Order; error?: string }> {
	const { data, error } = await supabase
		.from("orders")
		.select()
		.eq("id", orderId)
		.maybeSingle();
	return {
		data: data ? (toCamelCaseJson(data) as Order) : undefined,
		error: error?.message,
	};
}

export function subscribeToChanges({
	onChanges,
	onSubscriptionStatusChanges,
}: SubscribeToChangesProps): { unsubscribe: () => void } {
	const channel = supabase
		.channel("custom-all-channel")
		.on<Order>(
			"postgres_changes",
			{ event: "UPDATE", schema: "public", table: "orders" },
			(payload) => {
				onChanges &&
					onChanges({
						eventType: payload.eventType as EventEnum,
						new:
							Object.keys(payload.new).length !== 0
								? (toCamelCaseJson(payload.new) as Order)
								: undefined,
						old: payload.old,
					});
			}
		)
		.subscribe((status) => {
			onSubscriptionStatusChanges && onSubscriptionStatusChanges(status);
		});

	return { unsubscribe: async () => await supabase.removeChannel(channel) };
}
