import {useCreateStoryStore} from "@store/story.store";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

function Confirmation() {
  const {t} = useTranslation("generate");
  const cleanStory = useCreateStoryStore((state) => state.resetStory);
  useEffect(() => {
    cleanStory;
  }, []);
  return (
    <div className="container mt-4 min-h-[80vh] flex justify-center items-center">
      <div className="text-center space-y-4">
        <p className="text-2xl">{t("payment_successful")}</p>
        <p className="text-2xl">{t("payment_successful_desc")}</p>
        <h1 className="text-xl font-bold">{t("thank_you")}</h1>
      </div>
    </div>
  );
}

export default Confirmation;
