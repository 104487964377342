import { supabase } from "@config/supabaseClient";
import { JobFile } from "@models/job-file";

const BUCKET = import.meta.env.VITE_SUPABASE_STORAGE_BUCKET;

export async function uploadFiles(
  uploadDir: string,
  files: (File | Blob)[],
): Promise<{ files: JobFile[], errors: string[] }> {
  const downloadedFiles: JobFile[] = [];
  const errors: string[] = [];

  for (const file of files) {
    const fileName = `${Date.now()}_${Math.floor(Math.random() * 999999)}`;
    const filePath = `${uploadDir}/data-${fileName}`;

    const { data, error } = await supabase.storage
      .from(BUCKET)
      .upload(filePath, file, {
        cacheControl: "3600",
        upsert: false,
      });

    downloadedFiles.push({ path: data?.path || '', provider: 'SUPABASE' });
    errors.push(error?.message || '');
  }

  return {
    files: downloadedFiles,
    errors,
  };
}
