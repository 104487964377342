// import {useSearchParams} from "react-router-dom";
import {useCreateStoryStore} from "@store/story.store";
// import {FORM_TYPE_KEY} from "@pages/generate";
// import Header from "@components/generate/components/header";
// import {Button} from "@ui/button";
// import {useForm} from "react-hook-form";
// import {
//   Form,
//   FormControl,
//   FormField,
//   FormItem,
//   FormLabel,
//   FormMessage,
// } from "@ui/form";
// import * as z from "zod";
// import {zodResolver} from "@hookform/resolvers/zod";
import {RadioGroup, RadioGroupItem} from "@ui/radio-group";
// import {useEffect} from "react";
import SlideWrapper from "@components/generate/components/slide-wrapper";

const GenderForm = ({characterId}: {characterId: string}) => {
  const {data, editCharacterForm} = useCreateStoryStore((state) => state);
  // let [, setSearchParams] = useSearchParams();
  // const formSchema = z.object({
  //   gender: z.enum(["female", "male", "other"], {
  //     required_error: "You need to select your gender",
  //   }),
  // });
  // const form = useForm<z.infer<typeof formSchema>>({
  //   resolver: zodResolver(formSchema),
  // });

  // const gender = form.watch("gender");

  // useEffect(() => {
  //   updateForm({gender: gender});
  //   if (gender) {
  //     setSearchParams({[FORM_TYPE_KEY]: "3"});
  //   }
  // }, [gender]);

  return (
    <SlideWrapper>
      <div className="cards flex items-center justify-center">
        <div className="flex justify-center flex-col space-y-5">
          <h1 className="text-center font-bold text-lg">Select your gender</h1>
        </div>
      </div>
      <div className="flex items-center my-3 w-full">
        <RadioGroup
          onValueChange={(e) =>editCharacterForm(characterId, 'gender', 'value', e)}
          defaultValue={data.characters?.[characterId]?.formStages?.find(form=> form.name === 'gender')?.value as string || ''}
          value={data.characters?.[characterId]?.formStages?.find(form=> form.name === 'gender')?.value as string  || ''}
          className="flex flex-col space-y-1 w-full"
        >
          <div className="flex items-center space-x-3 space-y-0 p-4 rounded-md shadow cursor-pointer w-full">
            <RadioGroupItem value="female"/>
            <span className="w-full cursor-pointer">Female</span>
          </div>
          <div className="flex items-center space-x-3 space-y-0 p-4 rounded-md shadow cursor-pointer">
            <RadioGroupItem value="male" />
            <span className="w-full cursor-pointer">Male</span>
          </div>
          <div className="flex items-center space-x-3 space-y-0 p-4 rounded-md shadow cursor-pointer">
            <RadioGroupItem value="other" />
            <span className="w-full cursor-pointer">Other</span>
          </div>
        </RadioGroup>
      </div>
    </SlideWrapper>
  );
};

export default GenderForm;
