
export type CustomCharacterCategory = CustomCharacterMainCategory | CustomPersonCategory;

export enum CustomCharacterMainCategory {
  PERSON = "person",
  ANIMAL = "animal",
  TOY = "toy",
}

export enum CustomPersonCategory {
  BABY = "baby",
  YOUNG_BOY = "young boy",
  YOUNG_GIRL = "young girl",
  YOUNG_PERSON = "young person",
  BOY = "boy",
  GIRL = "girl",
  MAN = "man",
  WOMAN = "woman",
  OLD_MAN = "old man",
  OLD_WOMAN = "old woman",
  OLD_PERSON = "old person"
}

export enum CustomPersonAgeGroup {
  INFANT = "0-2", // Infants
  TODDLER = "3-6", // Toddlers
  ELEMENTARY_SCHOOL = "7-12", // Elementary School Age
  TEENAGER = "13-17", // Teenagers
  YOUNG_ADULT = "18-30", // Young Adults
  ADULT = "31-45", // Adults
  MIDDLE_AGE = "46-65", // Middle-Aged
  SENIOR = "66+", // Seniors
}

export enum CustomPersonGender {
  MALE = "male",
  FEMALE = "female",
  NEUTRAL = "neutral",
}

export interface CustomCharacter {
  /** ID unique within list in object. */
  id: string;
  name: string;
  toset?: string;
  category?: CustomCharacterCategory;
  gender?: CustomPersonGender;
  age?: CustomPersonAgeGroup;
  /** JobFile[] */
  files?: string;
  minFiles: number;
  maxFiles?: number;
}
