import SignInForm from "@components/login/sign-in.form";
import {useCreateEmailStore} from "@store/email.store";
import VerifyFormModal from "@components/modals/auth/verify-no-redirect.form";
import { useTranslation } from 'react-i18next';

export default function AuthenticationModal({
  redirectTo,
}: {
  redirectTo?: string;
}) {
  const {email} = useCreateEmailStore();
  const {t} = useTranslation("common")
  return (
    <>
      <div className="container relative h-[80vh] flex items-center justify-center">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          {email ? (
            <VerifyFormModal redirectTo={redirectTo} />
          ) : (
            <SignInForm redirectTo={redirectTo} bottomSheet />
          )}
          <p className="px-8 text-center text-sm text-muted-foreground">
            {t("login.by_clicking_continue_you_agree_to_our")}{" "}
            <a
              href="/terms"
              className="underline underline-offset-4 hover:text-primary"
            >
              {t("login.terms_of_service")}
            </a>{" "}
            {t("login.and")}{" "}
            <a
              href="/privacy"
              className="underline underline-offset-4 hover:text-primary"
            >
              {t("login.privacy_policy")}
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
}
