import * as CatalogAccess from "@access/catalog.access";
import * as JobAccess from "@access/job.access";
import { CatalogItem, CatalogItemWithImages } from "@models/catalog-item";
import { CatalogItemVariation } from "@models/catalog-item-variation";
import { CatalogModifier } from "@models/catalog-modifier";
import { CatalogModifierList } from "@models/catalog-modifier-list";
import { CatalogItemWithJobFlowTemplate } from "@typesClient/story";
import { convertLocaleToLanguage } from "@utils/convertLocaleToLanguage";

export async function getCatalogItem(
	itemId: string
): Promise<{ data?: CatalogItem; error?: string }> {
	return await CatalogAccess.getCatalogItem(itemId);
}

export async function getCatalogItemWithJobFlowTemplate(
	itemId: string
): Promise<{ data?: CatalogItemWithJobFlowTemplate; error?: string }> {
	const {data: catalogItem, error} = await CatalogAccess.getCatalogItem(itemId);
	const catalogItemWithCharacterList = catalogItem as CatalogItemWithJobFlowTemplate;

	if (catalogItem?.jobFlowTemplateId) {
		const { data, error } = await JobAccess.getJobFlowTemplate(catalogItem.jobFlowTemplateId);
		if (!error && data) {
			catalogItemWithCharacterList.jobFlowTemplate = data;
		}
	}

	return {data: catalogItemWithCharacterList, error};
}

export async function getAllCatalogItems(): Promise<{
	data?: CatalogItemWithImages[];
	error?: string;
}> {
	return await CatalogAccess.getAllCatalogItems();
}

export async function getAllCatalogItemsWithImages(language: string): Promise<{
	data?: CatalogItemWithImages[];
	error?: string;
}> {
	const {data: catalogItems, error} = await CatalogAccess.getAllCatalogItems();

	// filter catalog items based on the locale 
	const filteredCatalogItems = catalogItems?.filter(item => convertLocaleToLanguage(item?.locale) === convertLocaleToLanguage(language));
	return {data: filteredCatalogItems || [], error};
}


export async function getCatalogItemVariationsOfItem(
	itemId: string
): Promise<{ data?: CatalogItemVariation[]; error?: string }> {
	return await CatalogAccess.getCatalogItemVariationsOfItem(itemId);
}

export async function getCatalogModifierList(
	modifierListId: string
): Promise<{ data?: CatalogModifierList; error?: string }> {
	return await CatalogAccess.getCatalogModifierList(modifierListId);
}

export async function getCatalogModifiersOfList(
	modifierListId: string
): Promise<{ data?: CatalogModifier[]; error?: string }> {
	return await CatalogAccess.getCatalogModifiersOfList(modifierListId);
}
