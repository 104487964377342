import "@src/styles/global.css";
import "@config/i18n.ts";

import {queryClient} from "@config/query.config";
import Router from "@config/router";
import {QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {Toaster} from "@ui/toster";
import React from "react";
import ReactDOM from "react-dom/client";
import Sheet from "@components/sheet-slide";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
Sentry.init({
  dsn: "https://cf552879a0f41c665d1982a0d0b343f1@o4506144734838784.ingest.sentry.io/4506144756531200",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Router />
      <Toaster />
      <ReactQueryDevtools initialIsOpen={false} />
      <Sheet />
    </QueryClientProvider>
    </BrowserRouter>

  </React.StrictMode>
);
