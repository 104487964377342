import {MainNav} from "@components/main-nav";
import {UserNav} from "@components/user-nav";
import {useTranslation} from "react-i18next";
import {Link, Outlet, useLocation} from "react-router-dom";
import {useGetUserSession} from "@hooks/useGetUserSession";
import Footer from "@components/footer";
import ScrollToAnchor from "@components/scroll-to-anchor";
import {ROUTES} from "@config/routes";
import {Button} from "@ui/button";
import {Icons} from "./icons";
import {useState, useEffect} from "react";
import {cn} from "@lib/utils";
import FibbiLogo from "@assets/images/fibbi-logo.webp"
import Banner from "./banner";

const AppContainer = () => {
  const {user} = useGetUserSession();
  const {t} = useTranslation("common");
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      // Get the current scroll position of the body or any element you want
      const scrollY = window.scrollY || document.documentElement.scrollTop;

      // Check if it has scrolled by at least 10 pixels
      if (scrollY >= 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <ScrollToAnchor />
      <div>
      <Banner />
        <div className="flex justify-center">
          <div
            className={cn(
              "flex h-16 items-center justify-between md:justify-normal px-4 bg-white/70 backdrop-blur-xl fixed w-full duration-500 border-b z-50 transition-all top-24 sm:top-10",
              scrolled && location.pathname === "/" ? "w-92 translate-y-2 rounded-full" : ""
            )}
          >
            <Link to="/" className="">
              <img src={FibbiLogo} className="h-24 w-24" />
            </Link>
            <MainNav className="mx-6" />
            <div className="ml-auto items-center space-x-4 hidden md:flex">
              {user ? (
                <UserNav />
              ) : location.pathname === "/profile" ||
                location.pathname === "/sign-in" ? (
                <Link to="/" className="">
                  <Button variant={"outline"}>
                    <Icons.close className="w-6 h-6 text-primary" />
                  </Button>
                </Link>
              ) : (
                <Link
                  to={ROUTES.sign_in}
                  className="border border-input hover:bg-accent hover:text-accent-foreground p-2 rounded-md text-sm"
                >
                  {t("login.sign_in")}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="pt-60 sm:pt-36">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AppContainer;
