import "@src/styles/global.css";

import AppContainer from "@components/app-container.tsx";
import ErrorPage from "@pages/errorPage.tsx";
import Login from "@pages/login.tsx";
import {
  Route,
  useLocation,
  Routes,
} from "react-router-dom";

import Books from "@pages/books.tsx";
import Generate from "@pages/generate";
import LandingPage from "@pages/landing";
import OrderPage from "@pages/order";
import Checkout from "@pages/checkout";
import Terms from "@pages/terms-and-conditions";
import Confirmation from "@pages/order-confirmation";
import Profile from "@pages/profile";
import Imprint from "@pages/imprint";
import PrivacyPolicy from "@pages/terms-and-conditions";
import SignUp from "@components/login/sign-up.form";


const Router = () => {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route path="/" element={<AppContainer />} errorElement={<ErrorPage />}>
        <Route index element={<LandingPage />} />
        <Route path="books" element={<Books />} />
        <Route path="order/:orderId" element={<OrderPage />} />
        <Route path="checkout/:orderId" element={<Checkout />} />
        <Route path="order/:orderId/confirmation" element={<Confirmation />} />
        <Route path="profile" element={<Profile />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="imprint" element={<Imprint />} />

        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-in" element={<Login />} />
        <Route path="sign-with-mail" element={<Login />} />
      </Route>
      <Route path="generate/:itemId" index element={<Generate />} />
    </Routes>
  );
};

export default Router;
