import { supabase } from "@config/supabaseClient";
import { User } from "@models/user";
import { SubscribeToChangesProps } from "@services/user.service";
import { EventEnum } from "@typesClient/common";
import { toCamelCaseJson } from "@utils/string.utils";

export async function getUser(
	userId: string
): Promise<{ data?: User; error?: string }> {
	const { data, error } = await supabase
		.from("users")
		.select()
		.eq("id", userId)
		.maybeSingle();
	return {
		data: data ? (toCamelCaseJson(data) as User) : undefined,
		error: error?.message,
	};
}

export async function getAllUsers(): Promise<{ data: User[]; error?: string }> {
	const { data, error } = await supabase.from("users").select("*");
	return {
		data: data ? (toCamelCaseJson(data) as User[]) : [],
		error: error?.message,
	};
}

export async function removeUser(userId: string): Promise<{ error?: string }> {
	const { error } = await supabase.from("users").delete().eq("id", userId);
	return { error: error?.message };
}

export function subscribeToChanges({
	onChanges,
	onSubscriptionStatusChanges,
}: SubscribeToChangesProps): { unsubscribe: () => void } {
	const channel = supabase
		.channel("custom-all-channel")
		.on<User>(
			"postgres_changes",
			{ event: "*", schema: "public", table: "users" },
			(payload) => {
				onChanges &&
					onChanges({
						eventType: payload.eventType as EventEnum,
						new:
							Object.keys(payload.new).length !== 0
								? (toCamelCaseJson(payload.new) as User)
								: undefined,
						old: payload.old,
					});
			}
		)
		.subscribe((status) => {
			onSubscriptionStatusChanges && onSubscriptionStatusChanges(status);
		});

	return { unsubscribe: async () => await supabase.removeChannel(channel) };
}
