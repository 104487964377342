import teddy from "@assets/images/carousel/teddy.webp"
import teddyDrawing from "@assets/images/carousel/teddy-drawing.webp"
import dog from "@assets/images/carousel/dog.webp"
import dogDrwaing from "@assets/images/carousel/dog-drawing.webp"
import girl from "@assets/images/carousel/girl.webp"
import girlDrawing from "@assets/images/carousel/girl-drawing.webp"
import boy from "@assets/images/carousel/boy.webp"
import boyDrawing from "@assets/images/carousel/boy-drawing.webp"

import { Icons } from "@components/icons";
import { useState, useEffect } from 'react';

const Snowflake = ({ id } : {id: number}) => {
  const [animationDelay, setAnimationDelay] = useState('2s');
  const [fontSize, setFontSize] = useState('30px');

  const generateSnowflake = () => {
    const newDelay = `${(Math.random() * 30).toFixed(2)}s`;
    const newFontSize = `${Math.floor(Math.random() * 90)}px`;
    setAnimationDelay(newDelay);
    setFontSize(newFontSize);
  };

  useEffect(() => {
    generateSnowflake();
  }, []);

  const style = { animationDelay, fontSize };

  return (
    <p className='stars text-primary' id={`item${id}`} style={style}>
     <Icons.sparks className="fill-primary" style={{width: style.fontSize, height: style.fontSize}} />
    </p>
  );
};

const StarGround = () => {
  const numFlakes = 90;

  const snowflakes = Array.from({ length: numFlakes }, (_, i) => (
    <Snowflake key={i} id={i} />
  ));

  return <div className='star-ground transition-all absolute h-5/6 w-3 -translate-x-1/2 left-[48.5%] top-0 z-40'>{snowflakes}</div>;
};



const ImageItem = ({img}: {img: string})=>{
    return (
        <div className="relative aspect-[9.6/12] h-full py-1 px-1">
        <img
          width={300}
          height={300}
          className="hover-scale h-full w-full rounded-md border border-black/5 object-cover sm:rounded-lg"
          src={img}
        />
      </div>
    )
}

const SectionCarousel = () => {
  const imagesOriginal = [
    {image:  teddy },
    {image:  dog },
    {image:  boy },
    {image:  girl },
    {image:  teddy },
    {image:  dog },
    {image:  boy },
    {image:  girl },
    {image:  teddy },
    {image:  dog },
    {image:  boy },
    {image:  girl },
    {image:  teddy },
    {image:  dog },
    {image:  boy },
    {image:  girl },
  ]
  const imagesDrawing = [
    {image:  teddyDrawing },
    {image:  dogDrwaing },
    {image:  boyDrawing },
    {image:  girlDrawing },
    {image:  teddyDrawing },
    {image:  dogDrwaing },
    {image:  boyDrawing },
    {image:  girlDrawing },
    {image:  teddyDrawing },
    {image:  dogDrwaing },
    {image:  boyDrawing },
    {image:  girlDrawing },
    {image:  teddyDrawing },
    {image:  dogDrwaing },
    {image:  boyDrawing },
    {image:  girlDrawing },
  ]
  return (
    <div className="fade-in group relative grid h-[300px] my-5">
      <div className="relative mx-auto grid h-full w-full max-w-[2200px] grid-cols-2 py-1.5">
        <div
          className="absolute left-1/2 top-0 h-full w-full"
          style={{transform: `translate3d(-50%, 0px, 0px)`}}
        ></div>
        <div className="pointer-events-none absolute left-0 top-0 z-10 hidden h-full w-16 bg-gradient-to-r from-white min-[2200px]:block"></div>
        <div className="pointer-events-none absolute right-0 top-0 z-10 hidden h-full w-16 bg-gradient-to-l from-white min-[2200px]:block"></div>
        <div className="relative col-span-1 overflow-hidden">
          <div className="animate-transitions-strip flex min-w-[4000px] flex-nowrap absolute left-0 top-0 will-change-transform h-60">
          {imagesOriginal.map(({image}, index) => {
                return <ImageItem img={image} key={index} />
            })}
          </div>
        </div>
        <StarGround />
        <div className="relative col-span-1 overflow-hidden">
          <div className="animate-transitions-strip left-strip flex min-w-[4000px] flex-nowrap absolute left-0 top-0 will-change-transform h-60">
            {imagesDrawing.map(({image}, index) => {
                return <ImageItem img={image} key={index} />
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionCarousel;
