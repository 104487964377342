import {useLocation,useNavigate, useParams} from "react-router-dom";
import {Button} from "@ui/button";
import SlideWrapper from "@components/generate/components/slide-wrapper";
import {AnimatePresence, motion} from "framer-motion";
import {useEffect, useState} from "react";
import {
  CharacterUI,
  FormValue,
  StoryState,
  useCreateStoryStore,
} from "@store/story.store";
import {Character as CharacterComponent} from "@components/generate/components/character-card";
import GenderForm from "@components/generate/gender.form";
import {spring} from "@config/animation.config";
import {cn} from "@lib/utils";
import AgeForm from "@components/generate/age.form";
import FilesForm from "@components/generate/files.form";
import FloatingFooter from "./components/floating-footer";
import {useToast} from "@ui/use-toast";
import {Icons} from "@components/icons";
import {useGetCatalogItemQuery} from "@hooks/useGetCatalogItemQuery";
import {useDialogStore} from "@store/dialog.store";
import AuthenticationModal from "@components/modals/auth";
import useCreateOrder, {OrderStatusEnum} from "@hooks/useCreateOrder";
import useUser from "@hooks/useUser";
import {storageKeys} from "@config/common";
import {useCreateEmailStore} from "@store/email.store";
import {useTranslation} from "react-i18next";
import {mapToCharacterUI} from "@store/character.adapter";

type FormStatus = "SUCCESS" | "FAILED" | "IDLE" | "IN_PROGRESS";

const CharacterOverviewForm = () => {
  const {t} = useTranslation("generate");
  const [orderId, setOrderId] = useState<string>();
  const {email} = useCreateEmailStore();
  const {user} = useUser();
  const location = useLocation();
  const currentPath = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  const params = useParams();
  const {data: catalogItem} = useGetCatalogItemQuery(params?.itemId || "");
  const {show, triggerDialog} = useDialogStore((state) => state);
  const navigate = useNavigate();
  const [formSubmission, setFormSubmission] = useState<FormStatus>("IDLE");
  const {toast} = useToast();
  const {
    data: story,
    addCharacters,
    // resetFormStatusQuo,
    // addCharacterDetail,
    setActiveSelectedCharacterId,
  } = useCreateStoryStore((state) => state);
  const {handleCreateOrder} = useCreateOrder();

  const handleSelectCharacter = (id: string) => {
    setActiveSelectedCharacterId(id);
  };
  useEffect(() => {
    // check if the user has already sent an OTP
    if (email) {
      show({content: <AuthenticationModal redirectTo={currentPath} />});
    }
    if (user) {
      triggerDialog(false);
    }
  }, [email, user]);
  useEffect(() => {
    const storyFromStorage = localStorage.getItem(storageKeys.story);
    if (storyFromStorage) {
      const storyStateFromStorage = JSON.parse(storyFromStorage)
        .state as StoryState;
      if (
        storyStateFromStorage.data.characters &&
        Object.values(storyStateFromStorage.data.characters).length > 0
      ) {
        addCharacters(storyStateFromStorage.data.characters);
        return;
      }
      const characterMap: Record<string, CharacterUI> = {};
      catalogItem?.data?.jobFlowTemplate?.inputs.characters?.forEach((character) => {
        characterMap[character.id] = mapToCharacterUI(character);
      });
      addCharacters(characterMap);
    }
  }, [catalogItem]);

  const displayForm = (form: FormValue | "" | undefined) => {
    if (story.activeSelectedCharacterId) {
      switch (form) {
        case "age":
          return (
            <AgeForm
              characterId={story.activeSelectedCharacterId}
              key={story.activeSelectedCharacterId}
            />
          );
        case "gender":
          return (
            <GenderForm
              characterId={story.activeSelectedCharacterId}
              key={story.activeSelectedCharacterId}
            />
          );
        case "files":
          return (
            <FilesForm
              characterId={story.activeSelectedCharacterId}
              key={story.activeSelectedCharacterId}
            />
          );
      }
    }
  };
  const firstForm =
    story.activeSelectedCharacterId &&
    story?.characters?.[story.activeSelectedCharacterId]?.formStatusQuo?.[0];

  // const formStages =
  //   story.activeSelectedCharacterId &&
  //   story?.characters?.[story.activeSelectedCharacterId]?.formStages;
  // const handleSkipCharacter = () => {
  //   if (story.activeSelectedCharacterId && story.characters) {
  //     addCharacterDetail(story.activeSelectedCharacterId, "completed", true);
  //   }
  // };
  const statusOfAllForms =
    story.characters &&
    Object.values(story.characters)
      .map((character) => character.completed)
      .every((value) => value === true);

  const submitOrder = async () => {
    if (!user) {
      show({content: <AuthenticationModal redirectTo={currentPath} />});
      return;
    }
    // upload images to s3
    if (statusOfAllForms && story.characters) {
      setFormSubmission("IN_PROGRESS");
      const {status, order} = await handleCreateOrder(catalogItem);
      if (status === OrderStatusEnum.FAILED) {
        setFormSubmission("FAILED");
        return;
      }
      if (order) {
        setOrderId(order.id);
        setFormSubmission("SUCCESS");
      }
    } else {
      toast({
        variant: "destructive",
        description:
          "You are required to complete or skip the remaing characters",
      });
      setFormSubmission("FAILED");
      return;
    }
  };

  useEffect(() => {
    if (formSubmission === "SUCCESS" && orderId) {
      navigate(`/order/${orderId}`);
    }
  }, [formSubmission, orderId]);

  return (
    <SlideWrapper className="container">
      <div className="flex items-center justify-center mt-14">
        <div className="flex justify-center space-y-5 flex-col">
          <AnimatePresence>
            {!story.activeSelectedCharacterId && (
              <motion.div
                initial={{opacity: 0, y: 0}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -100}}
                transition={{...spring, type: "tween", duration: 0.3}}
                className="space-y-5"
              >
                {statusOfAllForms ? (
                  <>
                    <h1 className="text-center font-bold text-lg mt-6 mb-2">
                      {t("begin_personalizing_your_character_success_header")}
                    </h1>
                    <p className="text-center mt-6 mb-2">
                      {t("begin_personalizing_your_character_success")}
                    </p>
                  </>
                ) : (
                  <h1 className="text-center font-bold text-lg mt-4 mb-2">
                    {t("begin_personalizing_your_character")}
                  </h1>
                )}

                {!statusOfAllForms && (
                  <h2 className="text-center mb-4">
                    {t("begin_personalizing_your_character_desc")}
                  </h2>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <div
            className={cn(
              "flex justify-center",
              story.activeSelectedCharacterId && "character-grid-active",
              "transition-all ease-in duration-500 w-full flex gap-4 flex-row flex-wrap !mt-10"
            )}
          >
            {catalogItem?.data?.jobFlowTemplate?.inputs.characters?.map((character, index) => (
              <CharacterComponent
                key={index}
                character={character}
                isSelected={story.activeSelectedCharacterId === character.id}
                onClick={() => handleSelectCharacter(character.id)}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Based on Timons comment to remove this features. Will hide them incase if we need them later */}
      {/* {story.activeSelectedCharacterId &&
      formStages &&
      firstForm === formStages[formStages.length - 1].name ? (
        <Button
          className="flex justify-center items-center m-auto"
          onClick={() =>
            story.activeSelectedCharacterId &&
            resetFormStatusQuo(story.activeSelectedCharacterId)
          }
        >
          {t("edit")}
        </Button>
      ) : story.activeSelectedCharacterId &&
        story.characters &&
        Object.values(story.characters).length > 1 ? (
        <Button className="flex justify-center items-center m-auto" onClick={handleSkipCharacter}>
          {t("skip")}
        </Button>
      ) : (
        <></>
      )} */}
      <AnimatePresence>{displayForm(firstForm)}</AnimatePresence>
      {statusOfAllForms ? (
        <FloatingFooter className="flex justify-center">
          <Button
            variant={"default"}
            className="w-full sm:w-72"
            disabled={formSubmission === "IN_PROGRESS"}
            onClick={submitOrder}
          >
            {formSubmission === "IN_PROGRESS" ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Icons.atom className="mr-2 h-4 w-4" />
            )}
            Generate
          </Button>
        </FloatingFooter>
      ) : (
        <></>
      )}
    </SlideWrapper>
  );
};

export default CharacterOverviewForm;
