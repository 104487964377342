import axios from "axios";

import { Order } from "@models/order";

/**
 * Creates a new session on the stripe platform for order payment.
 *
 * @param order the order to pay
 */
export async function createPaymentSession(order: Order): Promise<{ data?: { sessionId: string, url: string } | undefined, error?: string }> {
  let errorMessage: string | undefined;
  let response: { sessionId: string, url: string } | undefined;

  try {
    const baseUrl = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;

    const body: any = {
      provider: 'STRIPE',
      orderId: order.id,
      successUrl: `${window.location.origin}/order/${order.id}/confirmation?paymentStatus=succeeded`,
      cancelUrl: `${window.location.origin}/order/${order.id}`
    };

    const { data } = await axios.post(
      baseUrl + "/payment/session",
      body,
    );
    response = data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      errorMessage = error.message;
    } else {
      errorMessage = 'Failed to create payment session: ' + error;
    }
  }

  return {
    data: response,
    error: errorMessage,
  };
}

/**
 * Creates a new payment intent on the stripe platform for order payment.
 *
 * @param order the order to pay
 */
export async function createPaymentIntent(order: Order): Promise<{ data?: string, error?: string }> {
  let errorMessage: string | undefined;
  let clientSecret: string | undefined;

  try {
    const baseUrl = import.meta.env.VITE_SUPABASE_FUNCTIONS_URL;

    const body: any = {
      provider: 'STRIPE',
      orderId: order.id,
    };

    const { data } = await axios.post(
      baseUrl + "/payment/intent",
      body,
    );

    clientSecret = data.clientSecret;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      errorMessage = error.message;
    } else {
      errorMessage = 'Failed to create payment intent: ' + error;
    }
  }

  return {
    data: clientSecret,
    error: errorMessage,
  };
}
