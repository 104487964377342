import "@src/styles/generate.css"
// import TypeForm from "@components/generate/type-human-or-pet.form";
// import WhatToExpectForm from "@components/generate/what-to-expect.form";
import { useSearchParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Header from "@components/generate/components/header";
import CharacterOverviewForm from "@components/generate/character-overview.form";
// import { useGetCatalogItemQuery } from "@hooks/useGetCatalogItemQuery";
import BookOverview from "@components/generate/book-overview";

export const FORM_TYPE_KEY = 'formType';
const Generate = () => {
    let [searchParams] = useSearchParams();
    const formType = searchParams.get(FORM_TYPE_KEY) || '0';
    const forms = {
        0 : <BookOverview />,
        1: <CharacterOverviewForm />,
    }[formType]
  return (
    <div className="mt-3">
       <Header
        formType={formType}
      />
      <AnimatePresence>
        {forms}
      </AnimatePresence>
    </div>
  );
};

export default Generate;
