import {Button} from "@ui/button";
import {Icons} from "@components/icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Section1 = () => {
  const {t} = useTranslation("common");

  return (
    <div className="w-full h-[40vh] flex flex-col items-center justify-center container sm:mt-0">
      <h1 className="text-center py-8">
        <span className="text-3xl font-bold sm:text-5xl">
          {t("turn_your_header")}
        </span>{" "}
        <span className="main-txt font-bold text-3xl sm:text-5xl  fancy pb-4">
          {t("coloring_pages")}
        </span>
        <div className="text-lg">{t("turn_your_description")}</div>
      </h1>
      <Link to="/books">
        <Button className="mt-0 sm:mt-2 mb-20 sm:mb-0 rounded-full fancy-hover p-6">
          <span className="text-llg">{t("create_your_own")}</span>
          <Icons.chevronRight />
        </Button>
      </Link>
    </div>
  );
};

export default Section1;
