export enum EventEnum {
	INSERT = "INSERT",
	UPDATE = "UPDATE",
	DELETE = "DELETE",
}

export type SubscriptionStatusTable =
	| "SUBSCRIBED"
	| "TIMED_OUT"
	| "CLOSED"
	| "CHANNEL_ERROR";

export enum SubscriptionStatusTableEnum {
	SUBSCRIBED = "SUBSCRIBED",
	TIMED_OUT = "TIMED_OUT",
	CLOSED = "CLOSED",
	CHANNEL_ERROR = "CHANNEL_ERROR",
}
