import { Order } from "@models/order";
import * as OrderService from "@services/order.service";
import { useQueryClient } from "@tanstack/react-query";
import {
	EventEnum,
	SubscriptionStatusTable,
	SubscriptionStatusTableEnum,
} from "@typesClient/common";
import { useEffect, useState } from "react";

const useSubscribeToOrder = () => {
	const queryClient = useQueryClient();
	const [subscriptionStatusTable, setSubscriptionStatusTable] =
		useState<SubscriptionStatusTable>(SubscriptionStatusTableEnum.CLOSED);

	const onChanges = (event: {
		eventType: EventEnum;
		new: Order | undefined;
		old: Partial<Order>;
	}): void => {
		switch (event.eventType) {
			case EventEnum.UPDATE:
				queryClient.setQueryData(["order"], (users: Order[] | undefined) => {
					if (users && event.new) {
						return users.map((user) => {
							if (user.id === event.old.id && event.new) {
								return event.new;
							}

							return user;
						});
					}
				});
				break;
			default:
				console.log("default");
				break;
		}
	};

	useEffect(() => {
		const { unsubscribe } = OrderService.subscribeToChanges({
			onChanges,
			onSubscriptionStatusChanges: setSubscriptionStatusTable,
		});
		return () => {
			unsubscribe();
		};
	}, []);

	return {
		status: subscriptionStatusTable,
	};
};

export default useSubscribeToOrder;
