import {Icons} from "@components/icons";
import {useGetOrderQuery} from "@hooks/useGetOrderQuery";
import useSubscribeToOrder from "@hooks/useSubscribeToOrder";
import {cn} from "@lib/utils";
import {OrderState} from "@models/order";
import {useDialogStore} from "@store/dialog.store";
import {useCreateStoryStore} from "@store/story.store";
import {Button} from "@ui/button";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@ui/form";
import * as z from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {RadioGroup, RadioGroupItem} from "@ui/radio-group";
import {MoneyUtils} from "@utils/money.utils";
import {FulfillmentState, FulfillmentType} from "@models/order-fulfillment";
import {useMutation} from "@tanstack/react-query";
import * as Order from "@services/order.service";
import {useTranslation} from "react-i18next";
import {useToast} from "@ui/use-toast";
import * as PaymentService from "@services/payment.service";
import SectionCarousel from "@components/landing/sectiom-carousel";
import useUser from "@hooks/useUser";
import AuthenticationModal from "@components/modals/auth";
import { getFileUrl } from "@utils/storage.utils";


const OrderPage = () => {
  const {t} = useTranslation("generate");
  const [availableFulfillmentTypes, setAvalableFullmentsTypes] = useState<FulfillmentType[]>()
  const {show, triggerDialog} = useDialogStore((state) => state);
  const {resetStory} = useCreateStoryStore();
  const {toast} = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const {user} = useUser();
  const currentPath = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;



  const formSchema = z.object({
    fullfillmentType: z.nativeEnum(FulfillmentType, {
      required_error: "Please select book type",
    }),
  });
  const defaultValues = {
    fullfillmentType: FulfillmentType.DIGITAL,
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues
  });
  const fullfillmentType = form.watch("fullfillmentType");
  const {
    mutate: handleUpdateFulfillmentType,
    isLoading,
    data: orderMutateData,
  } = useMutation({
    mutationKey: ["order", params?.orderId, fullfillmentType],
    mutationFn: async ({
      orderId,
      fulfillmentType,
    }: {
      orderId: string;
      fulfillmentType: FulfillmentType;
    }) => {
      return await Order.updateOrderFulfillmentType({orderId, fulfillmentType});
    },
  });
  const {data: orderDetails, isError, isFetched} = useGetOrderQuery({
    orderId: params?.orderId ?? null,
    fullfillmentType: fullfillmentType,
    onError: () => {
      toast({
        description: "Order not found",
        variant: "destructive",
      });
    },
  });
  useEffect(() => {
    if(isError && isFetched){
      if (!user) {
        show({content: <AuthenticationModal redirectTo={currentPath} />});
        navigate("/");
      }
    }
    if(orderDetails){
      triggerDialog(false)
    }
  }, [user, orderDetails, isError, isFetched]);
  useEffect(()=>{
    if(orderDetails){
      let fullfilmentTypes: FulfillmentType[] = [];
      orderDetails.lineItems.forEach((lineItem)=>{
        fullfilmentTypes.push(...lineItem.availableFulfillmentTypes);
      })
      const uniqueFullmentTypes = Array.from(new Set(fullfilmentTypes));
      setAvalableFullmentsTypes(uniqueFullmentTypes);
      // set fulfilmentType since the selection would be disabled if there is only one value
      if(uniqueFullmentTypes.length === 1){
        form.setValue("fullfillmentType", uniqueFullmentTypes[0]);
      }
    }
  }, [orderDetails])
 
  const [showPreview, setShowPreview] = useState(false);
  useEffect(() => {
    if (
      orderDetails?.state === OrderState.OPEN &&
      orderDetails.fulfillments[0].state === FulfillmentState.COMPLETED
    ) {
      setShowPreview(true);
    }
    if(orderDetails?.state === OrderState.COMPLETED){
      navigate("/")
    }
  }, [orderDetails]);
  const {status: _status} = useSubscribeToOrder();
  const previewImagesString =
    orderDetails?.fulfillments?.[0]?.entries?.[0]?.metadata?.preview;
  const previewImages: any[] | undefined = previewImagesString
    ? JSON.parse(previewImagesString)
    : undefined;
  useEffect(() => {
    // Order has been placed successfully
    resetStory();
  }, []);

  const handleShowPreview = () => {
    if (
      orderDetails?.state === OrderState.OPEN &&
      orderDetails.fulfillments[0].state === FulfillmentState.COMPLETED
    ) {
      setShowPreview(true);
    }
  };
  const handlePreviewImage = (image: string) => {
    show({
      content: (
        <div className="mt-10">
          <div className="flex justify-center items-start rounded-lg">
            <img
              src={image}
              className="w-96 h-96 object-contain rounded-lg"
              alt="preview"
            />
          </div>
        </div>
      ),
    });
  };

  const updateHandleItemVariation = () => {
    if(!orderDetails) return;
    PaymentService.createPaymentSession(orderDetails)
    .then((response) => {
      if(response.error){
        toast({
          variant: "destructive",
          description: "An unexpected error occurred.",
        });
        return;
      }
      if(response.data?.url){
        // navigate to stripe
        window.location.href = response.data.url;
      }
    }).catch(_error=>{
      // handle error here
      toast({
        variant: "destructive",
        description: "An unexpected error occurred.",
      });
    });
  };
  return (
    <div className="min-h-[80vh] flex justify-center items-center">
      {!showPreview ? (
        <div className="container text-center mt-4">
          <div className="mb-3 space-y-2">
            <div>{t("stay_with_us")}</div>
          </div>
          {/* <Icons.circleCheck className="mr-2 h-24 w-24 text-primary" /> */}
          <Button
            disabled={!showPreview}
            className="mt-2"
            onClick={handleShowPreview}
          >
            {!showPreview ? (
              <>
                <div>{t("generating")}</div>{" "}
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin ml-2" />
              </>
            ) : (
              t("open_preview")
            )}
          </Button>
          <SectionCarousel />
          <p className="mb-3 mt-4 text-center">
            {t("close_wait_description")}
          </p>
          <Button className="mt-3" onClick={() => navigate("/")}>
            {t("close_and_wait_for_email")}
          </Button>
        </div>
      ) : (
        <div className="mt-4 container">
          <p className="text-center text-xl mb-2 font-bold">
            {t("love_what_you_see")}
          </p>
          <p className="text-center mb-10">{t("love_what_you_see_desc")}</p>
          <div className="w-full flex justify-normal sm:justify-center gap-3 snap-x overflow-x-auto pb-6">
            {previewImages &&
              previewImages.map((item, index) => (
                <div
                  key={index}
                  className="snap-start scroll-ml-6 shrink-0 relative first:pl-3 last:pr-3 cursor-pointer"
                  onClick={() =>
                    handlePreviewImage(
                      `${getFileUrl(item)}`
                    )
                  }
                >
                  <div className="w-6 absolute top-0 left-0 bottom-0"></div>
                  <img
                    className="relative shrink-0 w-40 h-40 object-contain rounded-lg shadow-lg"
                    src={`${getFileUrl(item)}`}
                  />
                  <Icons.search
                    className={cn(
                      "w-4 h-4 absolute top-2 right-2 text-primary",
                      index === previewImages.length - 1 && "right-5"
                    )}
                  />
                </div>
              ))}
          </div>
          <p className="text-center">{t("you_can_download_digital")}</p>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(updateHandleItemVariation)}
              className="flex flex-col justify-center items-center mb-4"
            >
              <FormField
                control={form.control}
                name="fullfillmentType"
                render={({field}) => (
                  <FormItem className={cn("space-y-1 mb-4", availableFulfillmentTypes && availableFulfillmentTypes.length === 1 ? "hidden" : "")}>
                    <FormLabel>{t("book_type")}</FormLabel>
                    <FormMessage />
                    <RadioGroup
                      onValueChange={async (e: FulfillmentType) => {
                        handleUpdateFulfillmentType({
                          orderId: params.orderId as string,
                          fulfillmentType: e,
                        });
                        field.onChange(e);
                      }}
                      defaultValue={field.value}
                      className="grid max-w-md grid-cols-3 gap-8 pt-2"
                    >
                      {availableFulfillmentTypes && availableFulfillmentTypes.map((variant) => {
                        return (
                          <FormItem key={variant}>
                            <FormLabel className="[&:has([data-state=checked])>div]:border-primary">
                              <FormControl>
                                <RadioGroupItem
                                  value={variant}
                                  className="sr-only"
                                />
                              </FormControl>
                              <div className="items-center rounded-md border-2 space-y-3 border-muted p-1 hover:border-accent">
                                <div className="text-center pointer-events-none">
                                  {variant}
                                </div>
                              </div>
                            </FormLabel>
                          </FormItem>
                        );
                      })}
                    </RadioGroup>
                  </FormItem>
                )}
              />
              <Button
                className="flex self-center justify-items-center mt-10"
                type="submit"
                disabled={isLoading}
              >
                {t("procceed_to_checkout")}
                <span className="ml-2">
                  {isLoading ? (
                    <Icons.spinner className="mr-2 h-4 w-4 animate-spin ml-2" />
                  ) : (
                    <>
                      {orderMutateData?.data?.totalMoney &&
                        MoneyUtils.showMoney(
                          orderMutateData?.data?.totalMoney,
                          true
                        )}
                      {!orderMutateData?.data?.totalMoney &&
                        orderDetails?.totalMoney &&
                        MoneyUtils.showMoney(orderDetails.totalMoney, true)}
                    </>
                  )}
                </span>
              </Button>
            </form>
          </Form>
          <div className="py-14 flex flex-col justify-center items-center space-y-5">
            <p className="text-center text-xl">{t("not_quite_right")}</p>
            <p className="text-center">{t("not_quite_right_desc")}</p>
            <Button onClick={() => navigate("/")} className="">
              {t("return_home_page")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderPage;
