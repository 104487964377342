import {cn} from "@lib/utils";
import {useTranslation} from "react-i18next";
import {Icons} from "./icons";
import {Button} from "@ui/button";
import {AnimatePresence, motion, useCycle} from "framer-motion";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useUser from "@hooks/useUser";
import {useEffect, useState} from "react";
import * as Auth from "@services/auth.service";
import { LanguageDropdown } from "@components/language-dropdown";

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: {opacity: 1},
};

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const {t} = useTranslation(["common", "user"]);
  const [links, setLinks] = useState([
    {
      name: "common:menu.links.how_it_works",
      to: "/#how_it_works",
      id: 2,
      type: "link",
    },
    {
      name: "common:menu.links.coloring_books",
      to: "/#coloring_books",
      id: 3,
      type: "link",
    },
    {name: "common:menu.links.faq", to: "/#faq", id: 4, type: "link"},
    {
      name: "common:login.sign_in",
      to: "/sign-in",
      id: 1,
      type: "button",
    },
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const {user} = useUser();
  useEffect(() => {
    if (user) {
      setLinks([
        {
          name: "common:menu.links.how_it_works",
          to: "/#how_it_works",
          id: 2,
          type: "link",
        },
        {
          name: "common:menu.links.coloring_books",
          to: "/#coloring_books",
          id: 3,
          type: "link",
        },
        {name: "common:menu.links.faq", to: "/#faq", id: 4, type: "link"},
        {
          name: "Profile",
          to: "/profile",
          id: 1,
          type: "button",
        },
        {
          name: "common:log_out",
          to: "/logout",
          id: 21,
          type: "button",
        },
      ]);
    }
  }, [user]);
  const [open, cycleOpen] = useCycle(false, true);
  const redirectHandler = async (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    to: string
  ) => {
    e.preventDefault();
    cycleOpen();
    if (to === "/logout") {
      await Auth.logout();
      navigate("/sign-in");
      return;
    }
    navigate(to);
  };
  return (
    <div className="md:w-full justify-center md:flex block">
      <nav
        className={cn(
          "items-center space-x-4 lg:space-x-6 hidden md:flex",
          className
        )}
        {...props}
      >
        {links.map(({name, to, id, type}) => {
          if (type === "link") {
            return (
              <a
                className="text-sm font-medium transition-colors hover:text-primary text-foreground"
                key={id}>
                {t(name)}
                </a>
            );
          } else if (type === "a") {
            return (
              <a
                className="text-sm font-medium transition-colors hover:text-primary text-foreground"
                key={id}
                href={to}
              >
                {t(name)}
              </a>
            );
          } else {
            return null;
          }
        })}
      </nav>

      {location.pathname === "/profile" || location.pathname === "/sign-in" ? (
        <Link to="/" className="md:hidden block">
          <Button variant={"outline"}>
            <Icons.close className="w-6 h-6 text-primary" />
          </Button>
        </Link>
      ) : (
        <div className="flex items-center">
         <Link to="/books">
            <Button className="mx-2 rounded-full fancy-hover px-2 sm:px-4 ">
              <span className="text-xs sm:text-base">{t("commom:get_started")}</span>
              <Icons.chevronRight />
            </Button>
          </Link>
          <LanguageDropdown />
          <Button
            onClick={() => cycleOpen()}
            className="flex md:hidden"
            variant="outline"
          >
            <AnimatePresence>
              {open ? (
                <motion.span
                  key="close"
                  initial={{rotate: 0}}
                  animate={{rotate: 45, transition: {duration: 0.3}}}
                  exit={{rotate: 0}}
                >
                  <Icons.close className="w-6 h-6 text-primary" />
                </motion.span>
              ) : (
                <motion.span
                  key="close"
                  initial={{rotate: 0}}
                  animate={{rotate: 0}}
                  exit={{rotate: 0}}
                >
                  <Icons.menu className="w-6 h-6 text-primary" />
                </motion.span>
              )}
            </AnimatePresence>
          </Button>
        </div>
      )}
      <AnimatePresence>
        {open && (
          <motion.aside
            className="fixed top-[4.4rem] right-0 left-0 z-50 flex h-60 shadow-lg w-full flex-col items-center justify-center bg-white rounded-lg"
            initial={{height: 0}}
            animate={{
              height: "16rem",
            }}
            exit={{
              height: 0,
              transition: {delay: 0.7, duration: 0.2},
            }}
          >
            <motion.div
              className="flex flex-col items-center space-y-4 px-4 w-full"
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
            >
              {links.map(({name, to, id, type}) => {
                if (type === "button") {
                  return (
                    <motion.span
                      key={id}
                      whileHover={{scale: 1.03}}
                      variants={itemVariants}
                      className="w-full"
                    >
                      <Button
                        key={id}
                        variant="outline"
                        className="font-bold w-full"
                        onClick={(e) => redirectHandler(e, to)}
                      >
                        {t(name)}
                      </Button>
                    </motion.span>
                  );
                } else {
                  return (
                    <Link
                      key={id}
                      to={to}
                      onClick={(e) => redirectHandler(e, to)}
                    >
                      <motion.span
                        whileHover={{scale: 1.1}}
                        variants={itemVariants}
                        className="font-bold cursor-pointer"
                      >
                        {t(name)}
                      </motion.span>
                    </Link>
                  );
                }
              })}
            </motion.div>
          </motion.aside>
        )}
      </AnimatePresence>
    </div>
  );
}
