import { cn } from "@lib/utils";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  link?: string;
  className?: string; 
  linkClass?: string; 
};

const SectionHeader: React.FC<Props> = ({ title, link, className, linkClass }) => {
  const { t } = useTranslation("generate");
  return (
    <div className={cn("flex items-center justify-between pb-3", className)}>
      <h2 className={cn("text-xl font-bold md:text-3xl text-center sm:text-left", className) }>{title}</h2>
      {link && (
        <Link to={link} className={cn("hover:text-primary/70 text-primary hidden sm:block w-20", linkClass)}>
            {t("view_all")}
        </Link>
      )}
    </div>
  );
};

export default SectionHeader;