import {useState, ChangeEvent, FormEvent} from "react";

import FloatingFooter from "@components/generate/components/floating-footer";
import SlideWrapper from "@components/generate/components/slide-wrapper";
import {useDialogStore} from "@store/dialog.store";
import {Button} from "@ui/button";
import {ASPECT_RATIO, centerAspectCrop, compressImage, getImageDimensions} from "@lib/image";
import {
  ImageContent,
  useCreateStoryStore,
} from "@store/story.store";
import {Icons} from "@components/icons";
import {useToast} from "@ui/use-toast";
import {AnimatePresence, motion} from "framer-motion";
import NewImageGallery from "./components/new-image-gallery";
import {fileListToArray} from "@utils/files.utils";
import * as StorageService from "@services/storage.service";
import {v4} from "uuid";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { getFileUrl } from "@utils/storage.utils";


export type FilesFormStatus = "READY" | "NOT_READY";
const MB_BYTES = 10 * 1024 * 1024; // 10MB Number of bytes in a megabyte.
const IMAGE_QUALITY = 0.6;
const ACCEPTED_MIME_TYPES = ["image/jpg", "image/jpeg", "image/png"];
export const MINIMUM_NO_IMAGE = 5;
const UPLOAD_DIR = "upload";

export default function FilesForm({characterId}: {characterId: string}) {
  const { t } = useTranslation("generate");
  const {
    data: story,
    addCharacterDetail,
    removeImageFromNewImages,
    setNewActiveImage,
  } = useCreateStoryStore();
  const [uploading, setUploading] = useState(false);

  const minImages = story?.characters?.[characterId]?.character.minFiles || MINIMUM_NO_IMAGE;
  const uploadedImages = story?.characters?.[characterId]?.newImages;
  const newImages = story?.characters?.[characterId]?.newImages;

  const {toast} = useToast();

  const isMinimumImageNotValid = uploadedImages
    ? uploadedImages?.length < minImages
    : true;

  const [finalStatusOfForm, setFinalStatusOfForm] =
    useState<FilesFormStatus>("NOT_READY");

  const {show} = useDialogStore((state) => state);

  const handleSubmitUploadedImages = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isMinimumImageNotValid) {
      toast({
        variant: "destructive",
        description: "You are required to upload at least 5 photos",
      });
      return;
    }
    if (finalStatusOfForm === "NOT_READY") {
      show({
        content: (
          <NewImageGallery
            characterId={characterId}
            setEditingModalSeen={setFinalStatusOfForm}
          />
        ),
      });
      return;
    }
    if (finalStatusOfForm === "READY" && story.characters) {
      addCharacterDetail(characterId, "completed", true);
    }
  };
  const handleValidateAndUploadToStore = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {

    const acceptedFiles = e.target.files ? fileListToArray(e.target.files) : [];
    const uploadedCharacterImagesWithPaths: ImageContent[] = [];
    await Promise.all(
      acceptedFiles.map(async (file) => {
        const isBiggerThan2Mb = file.size <=  2 * 1024 * 1024;
        const compressedImage = await compressImage(file, IMAGE_QUALITY, isBiggerThan2Mb);
        // validate the image it will never come to this condition since we are compressing the image
        const meetsSizeRequirement = compressedImage.size <= MB_BYTES;
        const isImage = ACCEPTED_MIME_TYPES.includes(compressedImage.type);

        if (!meetsSizeRequirement || !isImage) {
          toast({
            variant: "destructive",
            description: "One or more images do not meet the requirements.",
          });
          return false;
        }
        setUploading(true);
        const imageDimention = await getImageDimensions(compressedImage);
        const {files: uploadedFiles, errors: uploadErrors} =
          await StorageService.uploadFiles('SUPABASE', UPLOAD_DIR, [compressedImage]);
        const rescenterAspectCrop = centerAspectCrop(imageDimention.width, imageDimention.height, ASPECT_RATIO);
        if (uploadedFiles[0]) {
          uploadedCharacterImagesWithPaths.push({
            id: v4(),
            path: uploadedFiles[0].path,
            provider: uploadedFiles[0].provider,
            settings: rescenterAspectCrop,
          });

    const imagesFromStore = useCreateStoryStore.getState().data?.characters?.[characterId].newImages ?? [];
    const imagesObjectArray =
      imagesFromStore.length > 0
        ? [...imagesFromStore, {
          id: v4(),
          path: uploadedFiles[0].path,
          provider: uploadedFiles[0].provider,
          settings: rescenterAspectCrop,
        }]
        : [{
          id: v4(),
          path: uploadedFiles[0].path,
          provider: uploadedFiles[0].provider,
          settings: rescenterAspectCrop,
        }];
        addCharacterDetail(characterId, "newImages", imagesObjectArray);
        setNewActiveImage(characterId, imagesObjectArray[0]);
        }

        if (uploadErrors.join("").length) {
          toast({
            variant: "destructive",
            description:
              "Uploading some of the images failed. Please try again later",
          });
          Sentry.withScope(function (scope) {
            scope.setLevel("error");
            // The exception has the event level set by the scope (info).
            Sentry.captureException(new Error(uploadErrors.join("")));
          });
        }
      })
    )
      .catch((e) => {
        console.log("Error uploading files", e);
        toast({
          variant: "destructive",
          description:
            "Error: Uploading some of the images failed. Please try again later",
         duration: 5000,
        });
        Sentry.withScope(function (scope) {
          scope.setLevel("error");
          // The exception has the event level set by the scope (info).
          Sentry.captureException(e);
        });
      })
      .finally(() => {
        setUploading(false);
      });
  
  };
 
  const handleOpenNewImageGallery = (imageName: string) => {
    setNewActiveImage(
      characterId,
      story?.characters?.[characterId]?.newImages?.find(
        (imageSetting) => imageSetting.id === imageName
      )
    );
    show({
      content: (
        <NewImageGallery
          characterId={characterId}
          setEditingModalSeen={setFinalStatusOfForm}
        />
      ),
    });
  };
  const handleRemoveImage = (id: string) => {
    removeImageFromNewImages(characterId, id);
    const uploadedImages =  useCreateStoryStore.getState().data.characters?.[characterId]?.newImages;
    const isMinimumImageNotValid = uploadedImages ? uploadedImages.length < minImages : true;
    if(isMinimumImageNotValid){
      setFinalStatusOfForm("NOT_READY");
      addCharacterDetail(characterId, "completed", false);
    }
  }
  return (
    <SlideWrapper className="mt-6">
      <form onSubmit={(e) => handleSubmitUploadedImages(e)}>
        <div className="space-y-2 mb-6">
          <h1 className="text-center font-bold text-lg">{t("upload_your_photos")}</h1>
          <p className="text-sm text-center">
            {t("upload_your_photos_description", { minFiles: minImages })}
          </p>
        </div>
        <div className="flex flex-wrap justify-center pb-60 sm:max-w-2xl w-full m-auto">
          <AnimatePresence>
            {newImages &&
              newImages?.map((uploadedImage) => (
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  layout="position"
                  key={uploadedImage.id}
                  className="w-[calc(33%-0.5rem)] h-32 mr-2 mb-2 relative border rounded-lg"
                >
                  <button
                    type="button"
                    onClick={() => {
                      handleRemoveImage(uploadedImage.id)
                    }}
                    className="absolute right-2 top-2 backdrop-blur-sm bg-white/50 rounded z-20"
                  >
                    <Icons.close className="text-destructive" />{" "}
                  </button>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleOpenNewImageGallery(uploadedImage.id);
                    }}
                  >
                    <img
                      src={getFileUrl(uploadedImage)}
                      className="rounded-lg w-full h-32 object-cover border-1 shadow-md"
                    />
                  </div>
                </motion.div>
              ))}
            <motion.button
              layout="position"
              className="w-[calc(33%-0.5rem)] h-32 mb-2 relative bg-gray-100 flex justify-center items-center rounded-md shadow-md"
              type="button"
            >
              {uploading ? (
                <Icons.spinner className="h-8 w-8 text-primary animate-spin" />
              ) : (
                <Icons.add className="h-8 w-8 text-primary" />
              )}
              <input
                type="file"
                accept="image/png,image/jpeg,image/jpg"
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                multiple
                onChange={(e) => handleValidateAndUploadToStore(e)}
                disabled={uploading}
              />
            </motion.button>
          </AnimatePresence>
        </div>
        <FloatingFooter className="flex justify-center">
          <Button
            variant={isMinimumImageNotValid ? "secondary" : "default"}
            disabled={isMinimumImageNotValid}
            className="w-full sm:w-72"
            type="submit"
          >
           {t("continue")}
          </Button>
        </FloatingFooter>
      </form>
    </SlideWrapper>
  );
}
