import { Order } from "@models/order";
import * as PaymentAccess from "@access/stripe-payment.access";


export async function createPaymentSession(order: Order): Promise<{ data?: { sessionId: string, url: string } | undefined, error?: string }> {
  return PaymentAccess.createPaymentSession(order);
}

export async function createPaymentIntent(order: Order): Promise<{ data?: string, error?: string }> {
  return PaymentAccess.createPaymentIntent(order);
}
