import * as CatalogSerivce from "@services/catalog.service";
import { useQuery } from "@tanstack/react-query";

export function useGetCatalogItemsQuery({language = "en"} : {language: string}) {
	return useQuery({
		queryKey: ["catalog_items", language],
		queryFn: async () => {
			const data = await CatalogSerivce.getAllCatalogItemsWithImages(language)
			return data;
		},
	});
}
