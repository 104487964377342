import { useScrollToTop } from "@hooks/useScrollToTop";
import { getPrivacyByCountryCode } from "@utils/getPrivacyByCountryCode";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const Content = ({
  className,
  content,
}: {
  className?: string;
  content: string;
}) => (
  <div className={className} dangerouslySetInnerHTML={{__html: content}}></div>
);

const PrivacyPolicy = () => {
  useScrollToTop();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get("lang");
  const {i18n} = useTranslation();
  const content = getPrivacyByCountryCode(lang ? lang : i18n.language);
  return (
    <div>
      <Content className="container pt-16" content={content} />
    </div>
  );
};

export default PrivacyPolicy;
