import {StripePaymentElementOptions} from "@stripe/stripe-js";
import {Button} from "@ui/button";
import {useToast} from "@ui/use-toast";
import {useStripe, useElements, PaymentElement, AddressElement} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import { useGetOrderQuery } from "@hooks/useGetOrderQuery";
import { FulfillmentType } from "@models/order-fulfillment";
import { useTranslation } from "react-i18next";

export const CheckoutForm = () => {
  const {t} = useTranslation("generate");
  const [returnUrl, setReturnUrl] = useState<string>("");
  const params = useParams();
  const {data: orderDetails} = useGetOrderQuery({orderId: params?.orderId ?? null});
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const {toast} = useToast();
  useEffect(() => {
    setReturnUrl(
      `${window.location.origin}/order/${params.orderId}/confirmation`
    );
  }, []);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      result.error.type === "card_error" ||
      result.error.type === "validation_error"
    ) {
      toast({
        variant: "destructive",
        description: result.error.message,
      });
    } else {
      toast({
        variant: "destructive",
        description: "An unexpected error occurred.",
      });
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="w-full md:w-1/2 mx-auto">
        <PaymentElement className="mb-4" options={paymentElementOptions} />
        {orderDetails && orderDetails?.fulfillments[0].type === FulfillmentType.SHIPMENT && <AddressElement
            options={{mode: 'shipping', allowedCountries: ['DE', "US"]}}
          />}
        <Button className="mt-4 flex w-full md:w-24 mx-auto" disabled={!stripe && isLoading}>
          {t("pay_now")}
        </Button>
        <p className="mt-2 mb-10">{t("pay_now_note")}</p>
      </form>
    </>
  );
};
