import {Money} from "@models/money";

/**
 * Utility class for Money.
 */
export class MoneyUtils {
  /**
   * Returns the sum of two Money objects.
   *
   * @param money1 the first summand
   * @param money2 the second summand
   * @returns the sum
   */
  static add(money1: Money, money2: Money): Money {
    return {
      amount: +(money1.amount + money2.amount).toFixed(2),
      currency: money1.currency,
    };
  }

  /**
   * Returns the difference of two Money objects.
   *
   * @param minuend the minuend
   * @param subtrahend the subtrahend
   * @returns the difference = minuend - subtrahend
   */
  static subtract(minuend: Money, subtrahend: Money): Money {
    return {
      amount: +(minuend.amount - subtrahend.amount).toFixed(2),
      currency: minuend.currency,
    };
  }

  /**
   * Multiplies a Money object by a scalar.
   *
   * @param scalar the scalar to multiply
   * @param money the Money object to multiply
   * @returns the result of the scalar multiplication
   */
  static multScalar(scalar: number, money: Money): Money {
    return {
      amount: +(scalar * money.amount).toFixed(2),
      currency: money.currency,
    };
  }

  static showMoney(money: Money, showCurrency: boolean = true): string {
    let moneyString: string = "";

    switch (money.currency) {
      case "EUR":
        moneyString = (money.amount / 100).toFixed(2).replaceAll(".", ",");
        if (showCurrency) {
          moneyString = moneyString + " €";
        }
        break;
      case "DKK":
        moneyString = (money.amount / 100).toFixed(2).replaceAll(".", ",");
        if (showCurrency) {
          moneyString = moneyString + " DKK";
        }
        break;
      case "GBP":
        moneyString = (money.amount / 100).toFixed(2);
        if (showCurrency) {
          moneyString = moneyString + " GBP";
        }
        break;
      default:
        break;
    }

    return moneyString;
  }
}
