import { supabase } from "@config/supabaseClient";
import { EmailOtpType, Provider, User } from "@supabase/supabase-js";

export async function getUserName(userId: string): Promise<string | undefined> {
	const { data } = await supabase
		.from("users")
		.select("firstname")
		.eq("id", userId);
	return data?.[0]?.firstname;
}
export async function updateUserInfo(userId: string, data: {
	fullName: string;
}): Promise<{ error?: string }>{
	const { error } = await supabase
		.from("users")
		.update({ full_name: data.fullName })
		.eq("id", userId);

	return { error: error?.message }
}
export async function signUp(option: {
	email: string;
	password: string;
}): Promise<{ error?: string, data: User | null }> {
	const { data, error } = await supabase.auth.signUp({
		email: option.email,
		password: option.password,
	});
	return {
		error: error?.message,
		data: data?.user ? data.user : null,
	};
}

export async function signInWithSocialLogin({
	provider,
	redirectTo,
}: {
	provider: Provider;
	redirectTo?: string;
}): Promise<{ error?: string }> {
	const { error } = await supabase.auth.signInWithOAuth({ provider, options: {redirectTo: redirectTo } });
	return {
		error: error?.message,
	};
}

export async function login(option: {
	email: string;
	password: string;
}): Promise<{ error?: string, data: User | null }> {
	const { data, error } = await supabase.auth.signInWithPassword({ ...option });

	return {
		error: error?.message,
		data: data.user ? data.user : null,
	};
}
export async function signInWithOtp(option: {
	email: string;
	redirectTo?: string;
}): Promise<{ error?: string, data: User | null }> {
	const  {data, error} = await supabase.auth.signInWithOtp({
		email: option.email,
		options: {
		  emailRedirectTo: option.redirectTo,
		},
	  });
	return {
		error: error?.message,
		data: data.user ? data.user : null,
	};
}
export async function verifyOtp(option: {
	email: string;
	token: string;
	type: EmailOtpType;
	redirectTo?: string
}): Promise<{ error?: string, data: User | null }> {
	const {error, data} = await supabase.auth.verifyOtp({
		token: option.token,
		type: 'email',
		email: option.email,
		options: {
			redirectTo: option.redirectTo
		}
	  });
	return {
		error: error?.message,
		data: data.user ? data.user : null,
	};
}

export async function logout(): Promise<{ error?: string }> {
	const { error } = await supabase.auth.signOut();
	return {
		error: error?.message,
	};
}

export async function getSession(): Promise<{
	userId?: string;
	error?: string;
}> {
	const { data, error } = await supabase.auth.getSession();

	return {
		userId: data.session?.user?.id,
		error: error?.message,
	};
}

export function onSessionChange(
	callback: (result: { userId?: string }) => void
): { unsubscribe: () => void } {
	const {
		data: { subscription },
	} = supabase.auth.onAuthStateChange(async (_event, session) => {
		callback({ userId: session?.user?.id });
	});

	return { unsubscribe: () => subscription.unsubscribe() };
}
