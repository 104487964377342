import * as CatalogService from "@services/catalog.service";
import { useQuery } from "@tanstack/react-query";

export function useGetCatalogItemQuery(itemId: string) {
	return useQuery({
		queryKey: ["catalog_item", itemId],
		queryFn: async () => {
			const data = await CatalogService.getCatalogItemWithJobFlowTemplate(itemId)
			return data;
		},
	});
}
