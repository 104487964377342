import {cn} from "@lib/utils";
import {CatalogItemWithImages} from "@models/catalog-item";
// import {MoneyUtils} from "@utils/money.utils";
import {useGetCatalogItemVariationOfItem} from "@hooks/useGetCatalogItemVariationOfItem";
import { Button } from "@ui/button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icons } from "./icons";
interface CardProps {
  story: CatalogItemWithImages;
}

const Card = ({story}: CardProps) => {
  const {name, catalog_images, descriptionPlaintext} = story;
  const {data: variations} = useGetCatalogItemVariationOfItem(story.id);
  const {t} = useTranslation("generate");
  return (
    <>
      {variations && variations.data && variations.data.length > 0 ? (
        <div
          className={cn(
            "snap-start scroll-ml-6 shrink-0 relative px-1 my-1 md:pb-4 lg:my-4 w-64"
          )}
        >
          <Link to={`generate/${story.id}`} className="flex justify-center mt-4">
          <article className="rounded-lg shadow-lg border">
            <div className="rounded-t-lg overflow-hidden w-full h-72 flex justify-center">
            <img src={catalog_images?.[0]?.url} alt="" className="object-cover w-full object-top" loading="lazy" />
            </div>
            <div className="flex flex-col justify-between p-4 min-h-[100px] relative border-t">
              <header className="flex flex-col justify-between leading-tight">
                <h1 className="font-bold line-clamp-1">{name}</h1>
                <h1 className="text-foreground/80 hover:underline text-sm line-clamp-2">
                  {descriptionPlaintext}
                </h1>
              </header>
              <Icons.pdf className="w-4 h-4 text-primary absolute right-2" />
              {/* Hide price for now */}
              {/* <footer className="flex justify-end flex-col items-end">
                {variations.data.map((itemVariation) => (
                  <p
                    key={itemVariation.id}
                    className="text-sm text-gray-400 font-bold"
                  >
                    {`${itemVariation.name}: ${
                      itemVariation.priceMoney &&
                      MoneyUtils.showMoney(itemVariation.priceMoney, true)
                    }`}
                  </p>
                ))}
              </footer> */}
              <Button className="w-full mt-2">{t("personalize")}</Button>
            </div>
          </article>
          </Link>

        </div>
      ) : null}
    </>
  );
};

export default Card;
