import {Elements} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetOrderQuery } from "@hooks/useGetOrderQuery";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {CheckoutForm} from "@components/checkout/checkout.form";
import { MoneyUtils } from "@utils/money.utils";
import { UrlParamKeys } from "@config/common";

import * as PaymentService from "@services/payment.service";
import { useTranslation } from "react-i18next";
import { useToast } from "@ui/use-toast";
import { getFileUrl } from "@utils/storage.utils";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

const Checkout = () => {
  const {toast} = useToast();
  const [clientSecret, setClientSecret] = useState("");
  const {t} = useTranslation("generate");
  const params = useParams();
  let [, setSearchParams] = useSearchParams();
  const {data: orderDetails} = useGetOrderQuery({orderId: params?.orderId ?? null});
  const previewImagesString =
  orderDetails?.fulfillments?.[0]?.entries?.[0]?.metadata?.preview;
  const previewImages: any[] | undefined = previewImagesString
    ? JSON.parse(previewImagesString)
    : undefined;

  useEffect(() => {
    if (orderDetails) {
      PaymentService.createPaymentIntent(orderDetails)
        .then((response) => {
          setClientSecret(response.data || '');
          setSearchParams({[UrlParamKeys.payment_intent_client_secret]: clientSecret});
          if(response.error){
            toast({
              variant: "destructive",
              description: "An unexpected error occurred.",
            });
          }
        }).catch(_error=>{
          // handle error here
        });
    }
  }, [orderDetails]);

  const options: StripeElementsOptions = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret
  };

// TODO: check if the order has alrady been paid if so then reridrect to confirmation page
  return (
    <div className="container mt-4 min-h-[80vh]">
      <div className="mb-4">
        <h1 className="text-3xl font-bold mb-2">{t("checkout")}</h1>
        <h4 className="">{t("order_summary")}</h4>
        <div className="flex w-full justify-between">
          {orderDetails?.totalMoney && <div className="text-sm">{t("total_price")}: <span className="font-bold">{MoneyUtils.showMoney(orderDetails?.totalMoney, true)} </span></div>}
          {previewImages && <div className="relative -top-16 w-24 right-0 transition-all ease-out">{
              previewImages.map((item, index) => (
                <div
                  key={index}
                  className="cursor-pointer absolute hover:!z-40 first:-left-10 last:left-10 hover:scale-110 first:z-20 border-2 rounded-full border-primary"
                >
                  <div className="w-6 absolute top-0 left-0 bottom-0"></div>
                  <img
                    className="relative w-14 h-14 rounded-full shadow-lg"
                    src={`${getFileUrl(item)}`}
                  />
                 
                </div>
              ))}</div>}

        </div>
      </div>
      {clientSecret && <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>}
    </div>
  );
};

export default Checkout;
