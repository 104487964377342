import { Money } from "@models/money.ts";
import { FulfillmentType } from "@models/order-fulfillment";
import { OrderLineItemModifier } from "@models/order-line-item-modifier";

export enum OrderLineItemItemType {
  /** Indicates that the line item is an itemized sale. */
	ITEM = "ITEM",
  /** Indicates that the line item is a personalized and itemized sale. */
	ITEM_PERSONALIZED = "ITEM_PERSONALIZED",
  /**
   * Indicates that the line item is a gift card sale. Gift cards sold through the Orders API are sold in an unactivated
   * state and can be activated through the Gift Cards API using the line item uid.
   */
	GIFT_CARD = "GIFT_CARD",
}

/**
 * Represents a line item in an order.
 *
 * Each line item describes a different product to purchase,
 * with its own quantity and price details.
 */
export interface OrderLineItem {
  /** The CatalogItemVariation ID applied to this line item. */
  catalogItemVariationId: string;

  /** The name of the line item. */
  name: string;
  /** The name of the variation line item. */
  variationName: string;
  /**
   * The quantity purchased, as a string representation of a number.
   * This string must have a positive integer value.
   */
  quantity: number;

  /** The type of line item: an itemized sale, a non-itemized sale (custom amount), or the activation or reloading of a gift card. */
  itemType: OrderLineItemItemType;

  /** Indicating the fulfillment types available for this line item. */
  availableFulfillmentTypes: FulfillmentType[];

  /** The CatalogModifiers applied to this line item. */
  modifiers?: OrderLineItemModifier[];
  /**
   * The list of references to discounts applied to this line item. Each OrderLineItemAppliedDiscount has a discountUid
   * that references the uid of a top-level OrderLineItemDiscounts applied to the line item. On reads, the amount applied is populated.
   *
   * An OrderLineItemAppliedDiscount will be automatically created on every line item for all ORDER scoped discounts
   * that are added to the order. OrderLineItemAppliedDiscount records for LINE_ITEM scoped discounts must be added
   * in requests for the discount to apply to any line items.
   *
   * To change the amount of a discount, modify the referenced top-level discount.
   */
  // appliedDiscounts?: OrderLineItemAppliedDiscount[];
  /**
   * The list of references to taxes applied to this line item. Each OrderLineItemAppliedTax has a taxUid
   * that references the uid of a top-level OrderLineItemTax applied to the line item. On reads, the amount applied is populated.
   *
   * An OrderLineItemAppliedTax will be automatically created on every line item for all ORDER scoped taxes
   * added to the order. OrderLineItemAppliedTax records for LINE_ITEM scoped taxes must be added
   * in requests for the tax to apply to any line items.
   *
   * To change the amount of a tax, modify the referenced top-level tax.
   */
  // appliedTaxes: OrderLineItemAppliedTax[];

  /** The base price for a single unit of the line item. Does not include modifiers. */
  basePriceMoney: Money;
  /**
   * The total price of all item variations sold in this line item.
   * Calculated as basePriceMoney multiplied by quantity. Does not include modifiers.
   */
  variationTotalPriceMoney: Money;
  /**
   * The total price of all modifiers sold in this line item.
   * Calculated as sum of OrderLineItemModifier.totalPriceMoney. Does not include variations.
   */
  modifierTotalPriceMoney: Money;
  /**
   * The amount of money made in gross sales for this line item.
   * Calculated as the sum of the variation's total price and each modifier's total price.
   */
  grossSalesMoney: Money;
  /** The total discount amount of money to collect for the line item. */
  totalDiscountMoney: Money;
  /** The total tax amount of money to collect for the line item. */
  totalTaxMoney: Money;
  /** The total amount of money to collect for this line item. */
  totalMoney: Money;

  /**
   * Application-defined data attached to this order. Metadata fields are intended to store descriptive references
   * or associations with an entity in another system or store brief information about the object.
   * Platform does not process this field; it only stores and returns it in relevant API calls.
   * Do not use metadata to store any sensitive information (personally identifiable information, card details, etc.).
   *
   * Keys written by applications must be 60 characters or less and must be in the character set [a-zA-Z0-9_-].
   * Entries may also include metadata generated by the platform. These keys are prefixed with a namespace,
   * separated from the key with a ':' character.
   *
   * Values have a max length of 255 characters.
   *
   * An application may have up to 10 entries per metadata field.
   *
   * Entries written by applications are private and can only be read or modified by the same application.
   */
  metadata?: { [key: string]: string };
}
