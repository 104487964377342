import { Icons } from "@components/icons";
import { useTranslation } from "react-i18next";

const Privacy = () => {
    const { t } = useTranslation("common");
    return ( 
        <div className="bg-primary text-background my-4 mt-16">
            <div className="container py-20 space-y-10">
            <div className="text-center flex flex-col justify-center items-center space-y-6">
                <div className="text-4xl font-bold">{t("privacy_title")} </div>
                <Icons.privacy className="my-auto h-16 w-16" />
                <p>{t("privacy_title_desc")}</p>
            </div>
            <div className="text-center flex flex-col justify-center items-center space-y-6">
                <div className="text-4xl font-bold">{t("privacy_title_2")}</div>
                <Icons.handShake className="my-auto h-16 w-16" />
                <p>{t("privacy_title_desc_2")}</p>
            </div>
            </div>
        </div>
     );
}
 
export default Privacy;