export const getFileUrl = (file: { path: string, provider: string }): string => {
  if (file.provider === 'S3') {
    return `${import.meta.env.VITE_S3_BUCKET_URL}/${file.path}`;
  } else if (file.provider === 'SUPABASE') {
    return `${import.meta.env.VITE_API_SUPABASE_URL}/storage/v1/object/public/${import.meta.env.VITE_SUPABASE_STORAGE_BUCKET}/${file.path}`;
    // image transformation only possible in paid plan
    // return `${import.meta.env.VITE_API_SUPABASE_URL}/storage/v1/render/image/public/orders/${file.path}?width=300&quality=90`;
  } else {
    return file.path;
  }
};
