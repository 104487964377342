import {useDialogStore} from "@store/dialog.store";
import {
  Sheet as SheetDialog,
  SheetContent,
} from "@ui/sheet";


export default function Sheet() {
  const {showDialog, triggerDialog, content} = useDialogStore((state) => state);
  return (
    <>
      <SheetDialog open={showDialog}>
        <SheetContent
          side="bottom"
          className="h-[calc(100vh-74px)] overflow-y-scroll"
          callbackCloseHandler={() => triggerDialog(false)}
        >
          {content}
        </SheetContent>
      </SheetDialog>
    </>
  );
}
