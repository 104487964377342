import {useScrollToTop} from "@hooks/useScrollToTop";
import {useTranslation} from "react-i18next";

const Imprint = () => {
  const {t} = useTranslation("common");
  useScrollToTop();
  return (
    <div className="container mt-4 h-[72vh]">
      <div className="text-2xl font-bold mb-5">{t("imprint")}</div>
        <div>
            <div>{t("trademark")}</div>
            <div>{t("street")}</div>
            <div>{t("post_code_city")}</div>
            <div>{t("managing_director")}:</div>
            <div>Vinh-Hieu On</div>
            <div>Timon von Bargen</div>
            <div>{t("register_court")}</div>
            <div>{t("registration_number")}: HRB 208888 B</div>
            <div>{t("vat_id")}: DE325616393</div>
        </div>
        <div>
            <div className="font-bold mt-2">{t("terms_of_use")}</div>
            <div className="text-sm">{t("terms_of_use_desc")}</div>
        </div>
    </div>
  );
};

export default Imprint;
